import styled from "styled-components";

export const ShippingLabelModelStyled = styled.div`
  .create-shipping-label-content {
    // display: block;
    padding-right: 20px;
    // padding-top: 30px;
    h4 {
      color: #000;
      text-align: left;

      font-family: "Noto Serif";
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .buyer-shipping-sec-header {
    background: #f9f9f9;
    height: 69px;
    padding-top: 18px;
    margin-top: 30px;
    padding-left: 33px;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.08);
  }
  .body-content-shipping-sec {
    padding-left: 33px;
    padding-right: 33px;
  }
  .buyer-adddress-sec {
    margin-top: 24px;
    color: #000;

    font-family: "Noto Serif";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .saved-adddress-sec {
    margin-top: 24px;
    color: #000;

    /* Paragraph Noto serif */
    font-family: "Noto Serif";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.888px; /* 161.8% */
  }
  .saved-address-item {
    padding: 16px;
    display: flex;
    margin-bottom: 12px;
    gap: 16px;
    border-radius: 4px;
    border: 1px solid var(--Light-grey, #d9d9d9);
    p {
      margin-bottom: 2px;
    }
  }
  .create-shipping-actions {
    display: flex;
    justify-content: end;
    gap: 16px;
    margin-top: 30px;
    margin-bottom: 30px;
    button {
      text-align: center;
      padding-top: 20px;
    }
  }
  .create-shipping-cancel {
    border-radius: 4px;
    border: 1px solid #000;
    width: 198px;
    height: 50px;
    color: #000;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.6px; /* 135% */
    text-align: center;
    padding-top: 14px;
  }
  .create-shipping-save {
    border-radius: 4px;
    width: 228px;
    height: 50px;
    color: #fff;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.6px; /* 135% */
    text-align: center;
    padding-top: 14px;
    background: #000;
  }
`;
