import { Button, Col, Container, Row } from "react-bootstrap";
import OpenTok from "opentok";
import { useState, useEffect } from "react";
import {
  SERVER_IP,
  VONTAGE_API_KEY,
  VONTAGE_API_SECRET,
} from "../../library/constants";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import "./styles.css";
var apiKey = VONTAGE_API_KEY;
var token = VONTAGE_API_SECRET;

let Auction = (props) => {
  console.log(SERVER_IP);
  const generateUUID = (prefix) => {
    return `${prefix}${uuidv4()}`;
  };
  // const apiKey = VONTAGE_API_KEY;
  let [publisher, setPublisher] = useState(null),
    [sessionIdVal, setSessionIdVal] = useState(""),
    [isAudio, setIsAudio] = useState(true),
    [isVideo, setIsVideo] = useState(true),
    [isLoadVideo, setIsLoadVideo] = useState(false);
  // let opentok = new OpenTok(VONTAGE_API_KEY, VONTAGE_API_SECRET),
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const handleError = (error) => {
    if (error) {
      console.log("error", error);
      // alert(error.message);
    }
  };
  const joinRoom = () => {
    console.log("vinod 233", window.OT);
    setIsLoadVideo(true);
    const sessionId = generateUUID("");

    setSessionIdVal(sessionId);
    var session = window.OT.initSession(apiKey, sessionId);

    // Subscribe to a newly created stream

    // Create a publisher
    var publisher = window.OT.initPublisher(
      "publisher",
      {
        insertMode: "append",
        height: "400px",
        width: "600px",
      },
      handleError
    );
    setPublisher(publisher);
    setIsLoadVideo(false);
    updateLiveVideo(sessionId);
    session.connect(token, function (error) {
      // If the connection is successful, publish to the session
      if (error) {
        handleError(error);
      } else {
        session.publish(publisher, handleError);
      }
    });

    // // Connect to the session
    // session.connect(token, function (error) {
    //   // If the connection is successful, publish to the session
    //   if (error) {
    //     handleError(error);
    //   } else {
    //     session.publish(publisher, handleError);
    //     updateLiveVideo();
    //   }
    // });
    // var opentok = new OpenTok(VONTAGE_API_KEY, VONTAGE_API_SECRET);
    // var sessionId;
    // opentok.createSession({ mediaMode: "routed" }, function (error, session) {
    //   if (error) {
    //     console.log("Error creating session:", error);
    //   } else {
    //     sessionId = session.sessionId;
    //     console.log("Session ID: " + sessionId);
    //   }
    // });

    // opentok.createSession(
    //   { mediaMode: "routed", archiveMode: "always" },
    //   function (err, argSession) {
    //     let session = window.OT.initSession(
    //       VONTAGE_API_KEY,
    //       argSession.sessionId
    //     );
    //      console.log("vinod 233 444")
    //     session.connect(
    //       opentok.generateToken(session.sessionId, {}),
    //       function (error) {
    //         console.log("vinod 233 start")
    //         if (error) {
    //           console.log("Error connecting: ", error.name, error.message);
    //         } else {
    //           let publisher = window.OT.initPublisher("publisher", {
    //             insertMode: "append",
    //             height: "400px",
    //             width: "600px",
    //           });
    //           setPublisher(publisher);
    //           session.publish(publisher);
    //           setIsLoadVideo(false);
    //           params["streamCode"] = session.sessionId;

    //           axios
    //             .post(SERVER_IP + "/api/BroadCast/startLive", params)
    //             .then((response) => console.log(response));

    //           axios
    //             .post(
    //               "https://api-fahh.azurewebsites.net/api/BroadCast/startLive",
    //               params
    //             )
    //             .then((response) => console.log(response));
    //         }
    //       }
    //     );
    //   }
    // );
  };

  const updateLiveVideo = (ssId) => {
    params["streamCode"] = ssId;
    console.log("params", params);
    axios
      .post(SERVER_IP + "/api/BroadCast/startLive", params)
      .then((response) => console.log(response));

    // axios
    //   .post(
    //     "https://api-fahh.azurewebsites.net/api/BroadCast/startLive",
    //     params
    //   )
    //   .then((response) => console.log(response));
  };

  console.log("publisher", publisher);
  return (
    <Container className="mt-2">
      <Row className="mt-1">
        <Col md={{ span: 6, offset: 3 }}>
          {isLoadVideo && (
            <div className="spinner-borderWord">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div
            id="publisher"
            className="text-center aligned-items-center"
          ></div>
          {publisher ? (
            <div
              id="publisher33"
              className="text-center aligned-items-center"
            ></div>
          ) : (
            <div
              style={{ height: "100px", width: "500px" }}
              className="text-center aligned-items-center"
            ></div>
          )}
        </Col>
      </Row>

      {!publisher && (
        <Row className="controlButtons">
          <Col md={12}>
            <Button
              variant="primary"
              onClick={joinRoom}
              disabled={isLoadVideo || !params.collectionId}
            >
              Start video 2
            </Button>
          </Col>
        </Row>
      )}
      {publisher && (
        <>
          <Row className="controlButtons">
            <Col md={12}>
              <Button
                variant="primary"
                onClick={() => {
                  publisher.publishAudio(!isAudio);
                  setIsAudio(!isAudio);
                }}
              >
                {isAudio ? "Mute" : "Unmute"}
              </Button>
              <Button
                className="ml-1"
                variant="primary"
                onClick={() => {
                  publisher.publishVideo(!isVideo);
                  setIsVideo(!isVideo);
                }}
              >
                {isVideo ? "Stop" : "Start"} Video
              </Button>
              <Button
                className="ml-1"
                variant="primary"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Disconnect
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default Auction;
