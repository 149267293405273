import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Card, Button } from "react-bootstrap";
import { ReactComponent as BackArrowIcon } from "./../../assets/BackArrow.svg";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeftBold.svg";
import AddLotForm from "./../../components/AddLotForm";
import AddMediaForm from "./../../components/AddMediaForm";
import AddLotDescription from "./../../components/AddLotDescription";
import AddProvenanceForm from "./../../components/AddProvenanceForm";
import AddOCLIForm from "./../../components/AddOCLIForm";
import LotShippingDetails from "./../../components/LotShippingDetails";
import ReviewForm from "./../../components/ReviewForm";
import ConditionReport from "./../../components/ConditionReport";
import { useParams } from "react-router-dom";
import { triggerEventForProfileUpdate } from "../../Scenes/common";

import {
  getAllCategories,
  getcollectionitembyidCall,
  getCollectionItemsCall,
} from "../../library/Requests";
import "./styles.css";
import PreviewLot from "./../../pages/PreviewLot";
import { UPDATE_LOT_DATA } from "../../library/Requests/eventConstants";
const CreateLot = (props) => {
  let { id } = useParams();

  const [allCategories, setAllCategories] = useState([]);
  const [activeKeyAcc, setActiveKeyAcc] = useState("0");
  const [collectionItemId, setCollectionItemId] = useState("");
  const [collectionId, setCollectionId] = useState();
  const [lotItemDetailsData, setLotItemDetailsData] = useState({});
  const [lotItemID, setLotItemID] = useState();
  const [collectionItems, setCollectionItems] = useState([]);
  const [isEditLot, setIsEditLot] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  useEffect(() => {
    getAllCategoriesCall();
    window.scrollTo(0, 50);
    // getCollectionItems();
    let collID = 0;
    const searchLo = props.location.search || props.location.pathname;
    if (searchLo.indexOf("collectionId=") > 0) {
      collID = searchLo.replace("?collectionId=", "");
      setCollectionId(collID);
    } else if (searchLo.indexOf("collectionID=") > 0) {
      collID = searchLo.replace("?collectionID=", "");
      setCollectionId(collID);
    }
    if (searchLo.indexOf("dashboard/editLot") > 0) {
      collID = searchLo.replace("/dashboard/editLot/", "");
      setCollectionItemId(collID);
      getItemDetails(collID);
      setIsEditLot(true);
    }
  }, []);

  const getAllCategoriesCall = async () => {
    const resp = await getAllCategories();
    setAllCategories(resp.data);
  };
  const updateDefaultActiveKey = (key) => {
    setActiveKeyAcc(key);
  };
  const UpdatecollectionItemId = (id) => {
    setCollectionItemId(id);
  };
  const gotiCollectionpage = () => {
    props.history.push(`/dashboard/collection/${collectionId}`);
  };
  const closePreviewPage = () => {
    setShowPreview(false);
  };
  const openPreviewPage = () => {
    window.scrollTo(0, 50);
    setShowPreview(true);
  };
  const getItemDetails = async (id) => {
    const idNew = id || collectionItemId;
    const resp = await getcollectionitembyidCall(idNew);
    setCollectionId(resp?.data?.collectionId);
    setLotItemDetailsData(resp?.data);
    triggerEventForProfileUpdate(UPDATE_LOT_DATA);
  };

  const DynamicClaPreview = showPreview
    ? "createlotContainPageHide"
    : "createlotContainPage";
  const DynamicClaLot = !showPreview
    ? "previewContainPageHide"
    : "previewContainPage";
  return (
    <div className="createLotContainer">
      <div className={DynamicClaPreview}>
        <Row className="headingRow" onClick={gotiCollectionpage}>
          <ChevronLeft className="chevronLeft" />
          <span className="lotManagementHeading">Collection</span>
        </Row>
        <input
          disabled
          type="hidden"
          value={collectionItemId}
          id="collectionLotID"
        ></input>
        <input
          disabled
          type="hidden"
          value={collectionId}
          id="collectionID"
        ></input>
        <Col sm={{ span: 4, offset: 2 }}>
          <p className="sectionHeading">
            {isEditLot ? <span>Edit Lot</span> : <span>Add Lot </span>}
          </p>
        </Col>
        <Row className="formsRow">
          <Accordion defaultActiveKey={activeKeyAcc}>
            <AddLotForm
              allCategories={allCategories}
              updateDefaultActiveKey={updateDefaultActiveKey}
              UpdatecollectionItemId={UpdatecollectionItemId}
              collectionIdNew={collectionId}
              isEditLot={isEditLot}
              lotItemDetails={lotItemDetailsData}
              collectionItemId={collectionItemId}
              getItemDetailsCall={getItemDetails}
            />
            <AddMediaForm
              isEditLot={isEditLot}
              collectionIdNew={collectionId}
              collectionItemId={collectionItemId}
              lotItemDetails={lotItemDetailsData}
              getItemDetailsCall={getItemDetails}
            />

            <AddLotDescription
              isEditLot={isEditLot}
              collectionIdNew={collectionId}
              collectionItemId={collectionItemId}
              lotItemDetails={lotItemDetailsData}
              getItemDetailsCall={getItemDetails}
            />

            <AddProvenanceForm
              isEditLot={isEditLot}
              collectionIdNew={collectionId}
              collectionItemId={collectionItemId}
              lotItemDetails={lotItemDetailsData}
              getItemDetailsCall={getItemDetails}
            />

            <ConditionReport
              isEditLot={isEditLot}
              collectionIdNew={collectionId}
              collectionItemId={collectionItemId}
              lotItemDetails={lotItemDetailsData}
              getItemDetailsCall={getItemDetails}
            />

            <AddOCLIForm
              isEditLot={isEditLot}
              collectionIdNew={collectionId}
              collectionItemId={collectionItemId}
              getItemDetailsCall={getItemDetails}
              lotItemDetails={lotItemDetailsData}
            />
            <LotShippingDetails
              isEditLot={isEditLot}
              collectionIdNew={collectionId}
              collectionItemId={collectionItemId}
              getItemDetailsCall={getItemDetails}
              lotItemDetails={lotItemDetailsData}
            />
            <ReviewForm
              isEditLot={isEditLot}
              collectionIdNew={collectionId}
              collectionItemId={collectionItemId}
              lotItemDetails={lotItemDetailsData}
              openPreviewPageCall={openPreviewPage}
            />
            {/* <AddConditonReport /> */}
          </Accordion>
        </Row>

        <Row className="btnGroup">
          <Col>
            <Button onClick={gotiCollectionpage} className="backToCollection">
              Back to Collection
            </Button>
          </Col>
          <Col className="addMoreBtnColumn">
            <Button onClick={gotiCollectionpage} className="addMoreLotsBtn">
              {isEditLot ? "Save Lot" : "Create Lot"}
            </Button>
          </Col>
        </Row>
      </div>
      <div className={DynamicClaLot}>
        <PreviewLot
          isEditLot={isEditLot}
          collectionIdNewId={collectionId}
          collectionItemId={collectionItemId}
          lotItemDetails={lotItemDetailsData}
          closePreviewPageCall={closePreviewPage}
          showPreview={showPreview}
        />
      </div>
    </div>
  );
};

export default CreateLot;
