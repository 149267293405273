import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { ReactComponent as PlusIcon } from "./../../assets/plusIcon.svg";
import { ReactComponent as EditPencil } from "./../../assets/editPencil.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/deleteIcon.svg";
import "./styles.css";
import {
  createitemlogisticinfoCall,
  edititemlogisticinfoCall,
  getcountriesCall,
  getstatesCall,
  getcitysCall,
  createitemShippingDetailsCall,
  edititemShippingDetailsCall,
} from "./../../library/Requests";

import React, { useState, useEffect, Component } from "react";
import {
  Row,
  Col,
  Accordion,
  Card,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import "./styles.css";
import { UPDATE_LOT_DATA } from "../../library/Requests/eventConstants";

import { withRouter } from "react-router-dom";
import Script from "react-load-script";

class LotShippingDetails extends Component {
  static propTypes = {};

  stateEm = {
    userData: {
      itemId: 0,
      originZipCode: "",
      handlingFee: "",
      weight: "",
      uom: "",
      width: "",
      height: "",
    },
    errorFields: {
      // ownerFirstName: false,
      // ownerLastName: false,
      // ownerCountry: false,
      // ownerState: false,
      weight: false,
      // ownerCity: false,
      // itemCountry: false,
      // itemCity: false,
      // contactFirstName: false,
      // contactLastName: false,
      // contactEmail: false,
      // ownerPhone: false,
      // contactPhoneNumber: false,
      // ownerAddress: false,
      // ItemAddress: false,
    },
    errorsData: {
      weight: "Please enter the weight",
      // ownerLastName: "Please enter the last name",
      // ownerCountry: "Please select the country",
      // ownerState: "Please select the state",
      // ownerEmail: "Please enter the email",
      // itemCountry: "Please select the country",
      // itemCity: "Please select the city",
      // contactFirstName: "Please enter the first name",
      // contactLastName: "Please enter the last name",
      // contactEmail: "Please enter the email",
      // ownerAddress: "Please enter the location",
      // ItemAddress: "Please enter the location",
      // ownerPhone: "Please enter the Phone number",
      // contactPhoneNumber: "Please enter the Phone number",
    },
  };
  state = {
    ...this.stateEm,
    saveContinue: false,
    allCountrysData: [],
    allStatesData: [],
    OwnerallStatesData: [],
    ItemallStatesData: [],
    validEmilOwner: true,
    contactEmailError: true,
    LogisticsCheckBox: false,
    itemLocationCheckBox: false,
  };

  componentDidMount = () => {
    this.updateState();
    const self = this;

    window.document.addEventListener(
      UPDATE_LOT_DATA,
      function (event) {
        self.updateState();
      },
      false
    );
    //   google.maps.event.addDomListener(window, 'load', self.initMap());
  };
  //   handleScriptLoad  =  ()=>{
  //     // const options = { types: [‘(cities)’] };

  //     var input = document.getElementById('locationTextField');
  //     var autocomplete = new google.maps.places.Autocomplete(input);

  //   }
  // handleScriptLoad = () => {
  //   // Declare Options For Autocomplete
  //   const options = {
  //     types: ["(cities)"],
  //   }; // To disable any eslint 'google not defined' errors
  //   // const options = {
  //   //   componentRestrictions: { country: "us" },
  //   // };
  //   // Initialize Google Autocomplete
  //   /*global google*/ this.autocomplete = new google.maps.places.Autocomplete(
  //     document.getElementById("locationTextField"),
  //     options
  //   );

  //   // Avoid paying for data that you don't need by restricting the set of
  //   // place fields that are returned to just the address components and formatted
  //   // address.
  //   this.autocomplete.setFields(["address_components", "formatted_address"]);

  //   // Fire Event when a suggested name is selected
  //   this.autocomplete.addListener("place_changed", this.handlePlaceSelectOwner);

  //   this.autocompleteItem = new google.maps.places.Autocomplete(
  //     document.getElementById("locationTextFieldItem"),
  //     options
  //   );

  //   // Avoid paying for data that you don't need by restricting the set of
  //   // place fields that are returned to just the address components and formatted
  //   // address.
  //   this.autocompleteItem.setFields([
  //     "address_components",
  //     "formatted_address",
  //   ]);

  //   // Fire Event when a suggested name is selected
  //   this.autocompleteItem.addListener(
  //     "place_changed",
  //     this.handlePlaceSelectItem
  //   );
  // };

  handlePlaceSelectOwner = () => {
    const addressObject = this.autocomplete.getPlace();
    this.handlePlaceSelect(addressObject);
  };

  handlePlaceSelectItem = () => {
    const addressObject = this.autocompleteItem.getPlace();
    this.handlePlaceSelect(addressObject, "ItemInfo");
  };

  // handlePlaceSelect = (addressObject, typeNo) => {
  //   console.log(addressObject);
  //   console.log(addressObject?.geometry?.location);
  //   const address1 = addressObject?.formatted_address;
  //   // Extract City From Address Object
  //   const address = addressObject?.address_components;
  //   let postcode = "";
  //   let city = "";
  //   let state = "";
  //   let country = "";
  //   for (const component of addressObject?.address_components) {
  //     const componentType = component.types[0];
  //     switch (componentType) {
  //       case "street_number": {
  //         // address1 = `${component.long_name} ${address1}`;
  //         break;
  //       }

  //       case "route": {
  //         // address1 += component.short_name;
  //         break;
  //       }
  //       case "postal_code_suffix": {
  //         // postcode = `${postcode}-${component.long_name}`;
  //         break;
  //       }

  //       case "postal_code": {
  //         postcode = `${component.long_name}${postcode}`;
  //         break;
  //       }
  //       case "locality":
  //         city = component.long_name;
  //         break;

  //       case "administrative_area_level_1": {
  //         state = component.long_name;
  //         break;
  //       }
  //       case "country":
  //         country = component.long_name;
  //         break;
  //     }
  //   }

  //   let userDatavalue = this.state.userData;
  //   if (address1) {
  //     if (typeNo == "ItemInfo") {
  //       userDatavalue.ownerAddress = address1;
  //     } else {
  //       userDatavalue.ItemAddress = address1;
  //     }
  //   }

  //   if (city != "") {
  //     if (typeNo == "ItemInfo") {
  //       userDatavalue.itemCity = city;
  //     } else {
  //       userDatavalue.ownerCity = city;
  //     }
  //   }
  //   if (state != "") {
  //     if (typeNo == "ItemInfo") {
  //       userDatavalue.itemState = state;
  //     } else {
  //       userDatavalue.ownerState = state;
  //     }
  //   }
  //   if (country != "") {
  //     if (typeNo == "ItemInfo") {
  //       userDatavalue.itemCountry = country;
  //     } else {
  //       userDatavalue.ownerCountry = country;
  //     }
  //   }

  //   this.setState({ userData: userDatavalue });
  //   // this.validateFormData()
  // };

  updateState = () => {
    this.getstatesData();
    console.log("this.props", this.props);
    // this.getCityData();
    const LotContactVal = this.props?.lotItemDetails?.itemPackage;
    // let LotContact = {};
    // if (
    //   LotContactVal != undefined &&
    //   LotContactVal != null &&
    //   LotContactVal.length > 0
    // ) {
    //   LotContact = LotContactVal[0];
    //   LotContact.ownerAddress = LotContact.ownerCity;
    //   LotContact.ItemAddress = LotContact.itemCity;
    // }
    this.setState({ userData: LotContactVal });
    // this.getCountryData();
    // if(name=="ownerCountry" || name=="itemCountry"){
    // this.getCitysByid(Cdata[index].id, name);
  };
  //   getCountryData  =  async()=>{
  //     const resp = await getcountriesCall()
  //     this.setState({allCountrysData: resp?.data});
  //     // const this.state?.userData?.ownerCountry
  //     // ownerCountry

  //   }
  getstatesData = async () => {};
  //  getCityData  =  async()=>{
  //    const resp = await getcitysCall()
  //    this.setState({allCitysData: resp?.data});
  //  }
  handleChangeValues = (e) => {
    console.log("this.props", this.props);
    const { name, value } = e.target;
    let state = { ...this.state.userData };

    let errorsList = this.state.errorFields;
    errorsList[name] = false;
    // this.setState({userData: state, errorFields: errorsList});

    if (name == "ownerPhone" || true) {
      const re = /^[0-9\b]+$/;
      if (re.test(value) || value === "") {
        state[name] = value;
        console.log("4444");
        this.setState({ userData: state, errorFields: errorsList });
      } else {
        console.log("5555");
        this.setState({ errorFields: errorsList });
      }
    } else {
      state[name] = value;
      this.setState({ userData: state, errorFields: errorsList });
    }
  };
  // getCitysByid = async (id, typev)=>{
  //     const resp = await getstatesCall(id);
  //     if(typev=="ownerCountry"){
  //         this.setState({ OwnerallStatesData: resp?.data});
  //     }else if (typev == "itemCountry"){
  //         this.setState({ ItemallStatesData: resp?.data});
  //     }

  // }
  validateFormData = () => {
    const details = this.state.errorFields;
    let errorsList = this.state.errorFields;
    const datanew = this.state.userData;
    let returnFlag = true;
    for (var key in details) {
      console.log(datanew[key]);
      console.log(key);
      const Pvalue = datanew[key];
      if (Pvalue == undefined || Pvalue == "" || Pvalue == null) {
        errorsList[key] = true;
        returnFlag = false;
      } else {
        errorsList[key] = false;
      }
    }

    this.setState({ errorFields: errorsList });
    return returnFlag;
  };

  saveandContinueName = async () => {
    const {
      userData,
      saveContinue,
      validEmilOwner,
      contactEmailError,
    } = this.state;

    if (this.validateFormData() && validEmilOwner && contactEmailError) {
      userData.itemId = document.getElementById("collectionLotID").value;
      // userData.id = userData?.id;

      // let body = { ...userData };
      let body = {
        itemId: parseInt(this.props?.collectionItemId),
        originZipCode:
          this.props?.lotItemDetails?.itemLogisticInfos?.[0]?.itemPostalCode ||
          this.props?.lotItemDetails?.itemLogisticInfos?.[0]?.ownerPostalCode ||
          "90034",
        handlingFee: parseInt(userData?.handlingFee),
        weight: parseInt(userData?.weight),
        uom: "Lb",
        length: parseInt(userData?.length),
        width: parseInt(userData?.width),
        height: parseInt(userData?.height),
        id: parseInt(userData?.id),
      };

      // body.itemId = ;

      if ((this.props?.isEditLot || saveContinue) && body?.id) {
        // body.id = state?.id;
        const respNew = await edititemShippingDetailsCall(body);
        if (respNew?.success) {
          this.setState({ saveContinue: true });
          this.props.getItemDetailsCall();
          document.getElementById("Accordion_Section_7").click();
        }
      } else {
        // body.itemId = document.getElementById("collectionLotID").value
        // const respNew = await createitemlogisticinfoCall(body);
        const respNew = await createitemShippingDetailsCall(body);
        if (respNew?.success) {
          let state = this.state.userData;
          state.id = respNew?.id;
          this.setState({ userData: state, saveContinue: true });
          this.props.getItemDetailsCall();
          document.getElementById("Accordion_Section_7").click();
        }
      }
    }
  };

  render() {
    const {
      saveContinue,
      errorFields,
      errorsData,
      userData,
      allCountrysData,
      OwnerallStatesData,
      ItemallStatesData,
      validEmilOwner,
      contactEmailError,
      LogisticsCheckBox,
      itemLocationCheckBox,
    } = this.state;
    return (
      <>
        <div className="wrapper">
          <Card>
            <Accordion.Toggle
              id="Accordion_Section_6"
              as={Card.Header}
              eventKey="6"
            >
              <span className="formHeading">7. Shipping Details</span>
              {saveContinue ? (
                <Button className="AccordionEditBtn"> Edit </Button>
              ) : (
                <AngleUpIcon className="angleUpIcon" />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Body className="ocliBody">
                <Row>
                  <p className="formSideHeading">Shipping Details</p>
                  <div className="googleMapLocationBase1">
                    <div id="pac-container">
                      {/* <Form.Control isInvalid={errorFields?.ownerAddress} onChange={this.handleChangeValues} name="ownerFirstName" value={userData.ownerFirstName} className="mediumFields"  type="text" placeholder="John" /> */}
                      <Form.Label className="requiredField">
                        Origin ZIP Code
                      </Form.Label>
                      <Form.Control
                        isInvalid={errorFields?.ownerCity}
                        id="locationTextField"
                        className="form-control locationTextField"
                        type="text"
                        disabled={true}
                        placeholder="Enter location"
                        value={
                          this.props?.lotItemDetails?.itemLogisticInfos?.[0]
                            ?.itemPostalCode ||
                          this.props?.lotItemDetails?.itemLogisticInfos?.[0]
                            ?.ownerPostalCode ||
                          "90034"
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorFields?.ownerCity ? (
                          <>{errorsData?.ownerAddress}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="lot-shipping-details-sec">
                    <Row>
                      <Col sm="4" style={{ padding: 0 }}>
                        <Form.Label className="requiredField">
                          Handling fee
                        </Form.Label>
                        <div className="holding-free-sec-amount">
                          <div className="holding-free-sec-amount-doller">
                            <span>$</span>
                          </div>
                          <div className="holding-free-sec-amount-input">
                            <Form.Control
                              // isInvalid={errorFields?.handlingFee}
                              onChange={this.handleChangeValues}
                              name="handlingFee"
                              value={userData?.handlingFee}
                              className="mediumFields"
                              type="text"
                              placeholder="0.00"
                            />
                            {/* <Form.Control.Feedback type="invalid">
                              {errorFields?.ownerFirstName ? (
                                <>{errorsData?.ownerFirstName}</>
                              ) : null}
                            </Form.Control.Feedback> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4" style={{ padding: 0 }}>
                        <Form.Label>Item weight*</Form.Label>
                        <div className="item-weight-sec-con holding-free-sec-amount1">
                          <Form.Control
                            disabled={false}
                            onChange={this.handleChangeValues}
                            name="weight"
                            value={userData?.weight}
                            className="mediumFields"
                            type="text"
                            placeholder="20"
                          />
                          <Form.Control
                            name="countryCode"
                            // isInvalid={errors?.countryCode}
                            // onChange={this.handleChangeValue}
                            as="select"
                          >
                            <option value="in">in</option>
                            <option value="lb">lb</option>
                            <option value="lh">lh</option>
                          </Form.Control>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errorFields?.weight ? (
                            <>{errorsData?.weight}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </div>
                  <div className="lot-shipping-details-sec lot-shipping-details-sec1">
                    <p>Item size when packed*</p>
                  </div>
                  <div className="lot-shipping-details-sec-con">
                    <Col sm="4" style={{ padding: 0 }}>
                      <Form.Label>Length</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          disabled={false}
                          onChange={this.handleChangeValues}
                          name="length"
                          value={userData?.length}
                          className="mediumFields1"
                          type="text"
                          maxLength={10}
                          placeholder="2"
                        />
                        <InputGroup.Text id="basic-addon2">in</InputGroup.Text>
                      </InputGroup>
                    </Col>

                    <Col sm="4">
                      <Form.Label className="requiredField">Width</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          isInvalid={errorFields?.width}
                          onChange={this.handleChangeValues}
                          name="width"
                          value={userData?.width}
                          className="mediumFields1"
                          type="email"
                          maxLength={10}
                          placeholder="2"
                        />
                        <InputGroup.Text id="basic-addon2">in</InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col sm="4" style={{ padding: 0 }}>
                      <Form.Label className="requiredField">Height</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          isInvalid={errorFields?.height}
                          onChange={this.handleChangeValues}
                          name="height"
                          value={userData?.height}
                          className="mediumFields1"
                          type="text"
                          maxLength={10}
                          placeholder="2"
                        />
                        <InputGroup.Text id="basic-addon2">in</InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </div>
                </Row>

                <Row className="btnRow btnAlign">
                  <Button
                    className="saveContinue"
                    onClick={() => this.saveandContinueName()}
                  >
                    Save & Continue
                  </Button>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </>
    );
  }
}

export default withRouter(LotShippingDetails);
