import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Accordion,
  Card,
  Nav,
  Button,
} from "react-bootstrap";
import BannerAuction from "./../../components/BannerAuction";
import LotsCard from "./../../components/LotsCard";
import LotsViewCard from "./../../components/LotsViewCard";
import FilterDataComponent from "./../../components/FilterComponent";
import FilterComponent from "./../../components/FilterComponent";
import { ReactComponent as PrintIcon } from "./../../assets/printIcon.svg";
import { ReactComponent as DownloadIcon } from "./../../assets/downloadIcon.svg";
import moment from "moment";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import { ReactComponent as BlockIcon } from "./../../assets/blockIcon.svg";
import { ReactComponent as RedBlockIcon } from "./../../assets/redBlockIcon.svg";

import SearchComponent from "./../../components/SearchComponent";
import {
  UPDATE_FILTER_DATA,
  UPDATE_LOT_DATA,
} from "../../library/Requests/eventConstants";
import { triggerEventForProfileUpdate } from "../../Scenes/common";
import CustomModal from "./../../components/CustomModal";
import { useParams, useHistory } from "react-router-dom";
import {
  getAllCollectionRegistrations,
  getAllCollectionWinnerDetails,
  getCollectionItemsCall,
  deleteLotByIdCall,
  getcurrenciesCall,
  updateCollectionItemStatus,
  getCollectionByIdCall,
  blockBid,
  getAllSalesReport,
} from "./../../library/Requests";
import "./styles.css";
import ShippingLabelModel from "./ShippingLabelModel";

import { Table } from "antd";
const RowItemInfo = (props) => {
  console.log("propspropsprops", props);
  const { createShippingInfo } = props;
  const { lotDetails } = props?.item;
  // const createShippingInfo = () => {};
  return (
    <div>
      <div className="expand-sec-content-row-sec">
        <div className="winners-info-header">
          <div className="winners-info-header-left">
            <p>Lot Details</p>
            <span
              onClick={() => {
                createShippingInfo(lotDetails);
              }}
              className="create-shipping-label-sec"
            >
              Create Shipping label +
            </span>
            {/* <p>Tracking ID - #FED34567890 </p> */}
            <span></span>
            {/* <p>Shipping Status - Picked up</p> */}
          </div>
          <div className="winners-info-header-right">
            <span>Purchase Summary</span>
          </div>
        </div>
        <div className="winners-info-header-body">
          {lotDetails?.map((lotB) => {
            return (
              <div className="row-box-winners">
                <div className="image-sec-info-winners">
                  <div>
                    <img src={lotB?.imageUrl} />
                  </div>
                  <div>
                    <p>Lot {lotB?.sno}</p>
                    <p>{lotB?.lotName}</p>
                  </div>
                </div>
                <div className="summary-sec-info-winners">
                  <div>
                    <p>Hammer Price</p> <p>USD {lotB?.hammerPrice}</p>
                  </div>
                  <div>
                    <p>Buyer’s Premium</p> <p>USD {lotB?.buyerPremium}</p>
                  </div>
                  <div>
                    <p>Sales Tax</p> <p>USD {lotB?.salesTax}</p>
                  </div>
                  <div>
                    <p>Estimated Shipping Fee</p> <p>USD {lotB?.shippingFee}</p>
                  </div>
                  <div className="dotted-lines-sec"></div>
                  <div>
                    <p>Total Lot Purchase Price</p>{" "}
                    <p>USD {lotB?.lotPurchasePrice}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const ReportsInfo = (props) => {
  let { id } = useParams();
  const params = new URLSearchParams(props?.location?.search);
  const type = params.get("type");

  const [currenciesData, setCurrenciesData] = useState([]);
  const [collectionItems, setCollectionItems] = useState([]);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [collectionDetailFull, setCollectionDetailFull] = useState({});
  const [deleteLotInfo, setDeleteLotInfo] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [withDrawLotID, setWithDrawLotID] = useState();
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [withdrawSuccessModal, setWithdrawSuccessModal] = useState(false);
  const [rePublishLot, setRePublishLot] = useState(false);
  const [rePublishedSuccessModal, setRePublishedSuccessModal] = useState(false);
  const [collectionData, setCollectionData] = useState({});
  const [bannerTimeZ, setBannerTimeZ] = useState([]);
  const [jsonBody, setJsonBody] = useState({});
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCnt, setTotalCnt] = useState(0);

  const [currentPages, setcurrentPages] = useState(1);
  const [pageData, setPageData] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);

  const [blockedId, setBlockedId] = useState("");
  const [showBlockUserPopup, setShowBlockUserPopup] = useState(false);
  const [showBlockSuccessUserPopup, setShowBlockSuccessUserPopup] =
    useState(false);
  const [showUnBlockUserPopup, setShowUnBlockUserPopup] = useState(false);
  const [showUnBlockSuccessUserPopup, setShowUnBlockSuccessUserPopup] =
    useState(false);

  const [showCreateLabelModel, setShowCreateLabelModel] = useState(false);
  const [createLabelData, setCreateLabelData] = useState({});

  useEffect(async () => {
    window.scrollTo(0, 0);
    getAllWinners();
  }, []);

  const getAllWinners = async (currentPages) => {
    setIsLoading(true);
    let resp = await getAllCollectionWinnerDetails(id, currentPage, pageData);
    setIsLoading(false);
    setRegisteredUsers(resp?.data);
    setTotalCount(resp.totalCount);
    var body1 = {
      page: 1,
      pageSize: 10,
      sortColumn: "",
      sortDirection: "",
      searchText: "",
      collectionId: 521,
    };
    let resp1 = await getAllSalesReport(body1);
  };
  const history = useHistory();
  const columns = [
    { title: "Full name", dataIndex: "name", key: "name" },
    {
      title: "No. of Lots Won",
      key: "noofLotsown",
      render: (ittm) => {
        return (
          <>
            <span>{ittm?.lotDetails?.length}</span>
          </>
        );
      },
    },

    { title: "Purchase Price", dataIndex: "totalAmount", key: "address" },
    { title: "Purchase Status", dataIndex: "paymentStatus", key: "address" },
    { title: "Invoice", dataIndex: "inVoice", key: "address" },
    Table.EXPAND_COLUMN,
  ];
  const createShippingInfo = (lDetails) => {
    console.log("lDetails", lDetails);
    setCreateLabelData(lDetails);
    setShowCreateLabelModel(true);
  };
  // const downLoadCall=()=>{
  //     history.push(`/dashboard/collectionDataDownload/${id}`);
  // }
  // const printCall=()=>{
  //     history.push(`/dashboard/collectionDataPrint/${id}`);
  // }
  console.log("registeredUsers", registeredUsers);
  return (
    <>
      <div className="table-view-winners-info">
        <Tab.Container id="collections-tabs" defaultActiveKey="browseLots">
          <Col sm="12" className="tabsColumn">
            <Nav variant="tabs" className="collectionsTabs">
              <Nav.Item>
                <Nav.Link eventKey="winners">Auction Summary</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="reports">Lots Paid</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>

          <Row className="filterLotsRow">
            <Col sm="12" className="cardMatrix">
              <Tab.Content>
                <Tab.Pane eventKey="winners">
                  <div className="winners-sec-info-cont">
                    {/* <WinnersInfo /> */}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="reports">
                  <div className="reports-sec-info-cont">
                    {/* <ReportsInfo /> */}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <div>
          <p>Auction Start Date 9/23/2023</p>
        </div>
        <div className="all-list-all-reports">
          <div>
            <p>Total number of Lots</p>
            <p>153</p>
          </div>
          <div>
            <p>Total number of Lots Sold</p>
            <p>153</p>
          </div>
          <div>
            <p>Sell Thru Rate</p>
            <p>153</p>
          </div>
          <div>
            <p>High Sale</p>
            <p>153</p>
          </div>
          <div>
            <p>Average Sale</p>
            <p>153</p>
          </div>
          <div>
            <p>Gross Auction Value</p>
            <p>153</p>
          </div>
          <div>
            <p>FAHH Net Revenue</p>
            <p>153</p>
          </div>
        </div>
        <ShippingLabelModel
          createLabelData={createLabelData}
          show={showCreateLabelModel}
          onCancel={() => {
            setShowCreateLabelModel(false);
          }}
        />
        <Table
          columns={columns}
          // rowSelection={{}}
          expandable={{
            expandedRowRender: (record) => (
              <RowItemInfo
                createShippingInfo={createShippingInfo}
                item={record}
              />
            ),
          }}
          dataSource={registeredUsers}
        />
      </div>
    </>
  );
};

export default ReportsInfo;
