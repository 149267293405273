import React, { useEffect, useState, Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router";
import { ReactComponent as ChevronLeft } from "./../../assets/chevronLeft.svg";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import { ReactComponent as PlusIcon } from "./../../assets/plusIcon.svg";
import moment from "moment";
import { useParams } from "react-router-dom";
import CustomModal from "./../../components/CustomModal";
import {
  getCollectionByIdCall,
  updateCollectionStatusId,
  getTimeZones,
} from "./../../library/Requests";
import "./styles.css";

class BannerComponent extends Component {
  static propTypes = {};

  state = {
    currentCollection: "",
    publishModal: false,
    publishSuccessModal: false,
    unPublishModal: false,
    unPublishSuccessModal: false,
    publishWarningPopup: false,
    durationLimit: null,
    bannerTimeZ: [],
    isUpcoming: true,
    id: null,
  };

  // const BannerComponent = (props) => {
  //     const [currentCollection, setCurrentCollection] = useState('')
  //     const [publishModal, setPublishModal] = useState(false);
  //     const [publishSuccessModal, setPublishSuccessModal] = useState(false);
  //     const [unPublishModal, setUnPublishModal] = useState(false);
  //     const [unPublishSuccessModal, setUnPublishSuccessModal] = useState(false);
  //     const [publishWarningPopup, setPublishWarningPopup] = useState(false);
  //     const [durationLimit, setDurationLimit] = useState();

  componentDidMount = async () => {
    const id = this.props?.match?.params?.id;
    this.getCollectionById(id);
    const bannerTimezResp = await getTimeZones();
    this.setState({ bannerTimeZ: bannerTimezResp.data, id: id });
    // setBannerTimeZ(bannerTimezResp.data);
  };
  getCollectionById = async (id) => {
    let resp = await getCollectionByIdCall(id);
    let someCollection = resp?.data;
    this.setState({ currentCollection: someCollection });
    this.checkUpcoming();
  };
  checkUpcoming = () => {
    const { currentCollection, bannerTimeZ } = this.state;
    // let { id } = useParams();
    const id = this.props?.match?.params?.id;
    const props = this.props;

    const { collectionData } = props;

    const onlineSD = moment(currentCollection?.onlineStartDate).format(
      "YYYY-MM-DD"
    );
    const liveSD = currentCollection?.biddingStartDate
      ? moment(currentCollection?.biddingStartDateOnUTC)
          .local()
          .format("YYYY-MM-DD")
      : moment(currentCollection?.startOnUtc).local().format("YYYY-MM-DD");

    const todayDate = moment().format("YYYY-MM-DD");

    const todayTime = moment.utc().local().format("h:m:s A");
    const utcTime = moment
      .utc(currentCollection?.startOnUtc)
      .local()
      .format("h:m:s A");
    const onlineTime = moment(currentCollection?.onlineStartTime).format(
      "h:m:s A"
    );

    const isOnlineAfter = moment(onlineSD).isAfter(todayDate);
    const isLiveAfter = moment(liveSD).isAfter(todayDate);

    const isOnlineEqual = moment(onlineSD).isSame(todayDate);
    const isLiveEqual = moment(liveSD).isSame(todayDate);

    if (isOnlineAfter) {
      const onlineHourDiff = moment
        .utc(currentCollection?.startOnUtc)
        .local()
        .diff(moment().local(), "hours");

      this.setState({ durationLimit: onlineHourDiff });
    } else if (isLiveAfter) {
      const myTime = currentCollection?.biddingStartDate
        ? currentCollection?.biddingStartDateOnUTC
        : currentCollection?.startOnUtc;
      const liveHourDiff = moment
        .utc(myTime)
        .local()
        .diff(moment().local(), "hours", "minutes");

      this.setState({ durationLimit: liveHourDiff });
    } else if (isOnlineEqual) {
      const onlineHourDiff = moment
        .utc(currentCollection?.startOnUtc)
        .local()
        .diff(moment().local(), "hours", "minutes");

      this.setState({ durationLimit: onlineHourDiff });
    } else if (isLiveEqual) {
      const liveHourDiff = currentCollection?.biddingStartDate
        ? moment
            .utc(currentCollection?.biddingStartDateOnUTC)
            .local()
            .diff(moment.utc().local(), "hours", "minutes")
        : moment
            .utc(currentCollection?.startOnUtc)
            .local()
            .diff(moment.utc().local(), "hours", "minutes");
      // const liveHourDiff =  moment.utc(currentCollection?.startOnUtc).local().diff(moment().local(), 'hours', 'minutes');

      this.setState({ durationLimit: liveHourDiff });
    } else {
      this.setState({ isUpcoming: false });
      const liveHourDiff = moment(currentCollection?.biddingStartDateOnUTC)
        .local()
        .diff(moment().local(), "hours", "minutes");
    }
  };
  publishLotConfirm = async (key) => {
    // setPublishModal(false)
    this.setState({ publishModal: false });
    let body = {
      id: this.state.id,
      status: 1,
    };
    let statusResp = await updateCollectionStatusId(body);
    if (statusResp?.success) {
      this.setState({ publishSuccessModal: true });
    } else if (statusResp?.errors[0] == "Incomplete data") {
      this.setState({ publishWarningPopup: true });
    }
  };
  unPublishLotConfirm = async (key) => {
    // setUnPublishModal(false)
    this.setState({ unPublishModal: false });
    let body = {
      id: this.state.id,
      status: 2,
    };
    let statusResp = await updateCollectionStatusId(body);
    if (statusResp?.success) {
      // setUnPublishSuccessModal(true)
      this.setState({ unPublishSuccessModal: true });
    } else if (statusResp?.errors[0] == "Incomplete data") {
      this.setState({ publishWarningPopup: true });
    }
  };
  goBackpage = () => {
    const { currentCollection } = this.state;
    const type = currentCollection?.status == 1 ? "?type=published" : "";

    this.props.history.push(`/dashboard/collections${type}`);
  };

  render() {
    const {
      currentCollection,
      publishModal,
      publishSuccessModal,
      unPublishModal,
      unPublishSuccessModal,
      publishWarningPopup,
      durationLimit,
      bannerTimeZ,
      isUpcoming,
    } = this.state;
    const onlineSD = moment(currentCollection?.onlineStartDate).format(
      "YYYY-MM-DD"
    );
    const liveSD = moment(currentCollection?.liveStartDate).format(
      "YYYY-MM-DD"
    );
    const todayDate = moment().format("YYYY-MM-DD");
    const isOnlineAfter = moment(onlineSD).isAfter(todayDate);
    const isLiveAfter = moment(liveSD).isAfter(todayDate);
    // let { id } = useParams();
    const id = this.props?.match?.params?.id;
    const props = this.props;
    let bannerTimeZone = bannerTimeZ.find(
      (i) => currentCollection?.timeZoneId === i?.id
    )?.abbrivation;
    if (!bannerTimeZone) {
      bannerTimeZone = bannerTimeZ.find(
        (i) => currentCollection?.timeZoneId === i?.id
      )?.name;
    }
    return (
      <div className="bannerContainer">
        <Row className="bannerRow">
          <Col
            sm={currentCollection?.status == 1 ? "9" : "8"}
            className="contentColumn columnStyles"
          >
            <p>
              <span onClick={this.goBackpage} style={{ cursor: "pointer" }}>
                <ChevronLeft className="chevronLeft" />{" "}
                <span className="bannerHeadingText">Lot Management</span>
              </span>{" "}
            </p>
            {currentCollection?.title && (
              <p>
                {currentCollection?.auctionType == "1" ? (
                  <>
                    <span className="date">
                      {moment(currentCollection?.onlineStartDate).format(
                        "MMMM Do YYYY"
                      )}
                    </span>
                    <Line className="lineStyles" />
                    <span className="time">
                      {" "}
                      {moment(
                        currentCollection?.onlineStartTime,
                        "HHmmss"
                      ).format("h:mm a")}{" "}
                      {bannerTimeZone}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="date">
                      {moment(currentCollection?.liveStartDate).format(
                        "MMMM Do YYYY"
                      )}
                    </span>
                    <Line className="lineStyles" />
                    <span className="time">
                      {" "}
                      {moment(
                        currentCollection?.liveStartTime,
                        "HHmmss"
                      ).format("h:mm a")}{" "}
                      {bannerTimeZone}{" "}
                    </span>
                  </>
                )}

                <Line className="lineStyles" />
                <span className="onlineAuction">
                  {" "}
                  {currentCollection?.auctionType === 1
                    ? "Online"
                    : "Live"}{" "}
                  Auction
                </span>
              </p>
            )}
            <p className="bannerTitle">{currentCollection?.title}</p>
          </Col>
          {currentCollection?.title && (
            <>
              {currentCollection.status == 1 ? (
                isUpcoming &&
                durationLimit > 4 && (
                  <>
                    <Col sm="3" className="btnColumn columnStyles">
                      <Row className="btnTopRow">
                        <Button
                          className="editBtn"
                          onClick={() => {
                            this.props.history.push(
                              `/dashboard/editCollection/${currentCollection?.id}`
                            );
                          }}
                        >
                          Edit
                        </Button>
                        {this.props?.itemCount > 0 && (
                          <>
                            <Button
                              className="publishBtn"
                              onClick={() =>
                                this.setState({ unPublishModal: true })
                              }
                            >
                              Unpublish
                            </Button>
                          </>
                        )}
                      </Row>
                      <Row className="btnBottomRow">
                        <Button
                          className="createLotBtn"
                          onClick={(event) => {
                            this.props.history.push(
                              `/dashboard/createLot?collectionId=${currentCollection?.id}`
                            );
                          }}
                        >
                          <PlusIcon className="plusIcon" />
                          Add Lot
                        </Button>
                      </Row>
                    </Col>
                  </>
                )
              ) : (
                <>
                  <Col sm="4" className="btnColumn columnStyles">
                    <Row className="btnTopRow">
                      <Button
                        className="editBtn"
                        onClick={() => {
                          this.props.history.push(
                            `/dashboard/editCollection/${currentCollection?.id}`
                          );
                        }}
                      >
                        Edit
                      </Button>
                      {this.props?.itemCount > 0 && (
                        <>
                          <Button
                            disabled={
                              this.props?.allLotIdsList.length > 0
                                ? false
                                : true
                            }
                            className="previewAndPublishBtn"
                            onClick={() => this.props.previewAndPublish()}
                          >
                            Preview and Publish
                          </Button>
                        </>
                      )}
                    </Row>
                    <Row className="btnBottomRow">
                      <Button
                        className="createLotBtn"
                        onClick={(event) => {
                          props.history.push(
                            `/dashboard/createLot?collectionId=${currentCollection?.id}`
                          );
                        }}
                      >
                        <PlusIcon className="plusIcon" />
                        Add Lot
                      </Button>
                    </Row>
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
        <CustomModal
          show={publishModal}
          titleText="Do you want to publish now?"
          deleteText="Publish"
          cancelText="Later"
          onCancel={() => this.setState({ publishModal: false })}
          onDelete={() => this.publishLotConfirm()}
        />
        <CustomModal
          show={publishSuccessModal}
          titleText="Lot Published Successfully"
          deleteText="ok"
          cancelText=""
          onCancel={() => this.setState({ publishSuccessModal: false })}
          onDelete={() => {
            this.setState({ publishSuccessModal: false });
            props.history.push(`/dashboard/collections`);
          }}
        />
        <CustomModal
          show={publishWarningPopup}
          titleText="Please fill the data for all lots"
          deleteText="ok"
          cancelText=""
          onCancel={() => {
            this.setState({ publishWarningPopup: false });
          }}
          onDelete={() => {
            this.setState({ publishWarningPopup: false });
          }}
        />
        <CustomModal
          show={unPublishModal}
          titleText="Do you want to Unpublish now?"
          deleteText="Unpublish"
          cancelText="Later"
          onCancel={() => this.setState({ unPublishModal: false })}
          onDelete={() => this.unPublishLotConfirm()}
        />
        <CustomModal
          show={unPublishSuccessModal}
          titleText="Lot Unpublished Successfully"
          deleteText="ok"
          cancelText=""
          onCancel={() => this.setState({ publishSuccessModal: false })}
          onDelete={() => {
            this.setState({ unPublishSuccessModal: false });
            this.props.history.push(`/dashboard/collections`);
          }}
        />
      </div>
    );
  }
}

export default withRouter(BannerComponent);
