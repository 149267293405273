import { get, post, put, del } from "./helpers";
import { API_BASE, SERVER_IP } from "../constants";

export * from "./OrderManagement";

export const loginActionCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Customer/login`, body);
};

export const createNewUser = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Customer/registerbusinessuser`,
    body
  );
};

export const createNewHSM = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/SiteManagement/createsitemanager`,
    body
  );
};

export const createNewCollection = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/createcollection`,
    body
  );
};
export const editCollection = async (body) => {
  return await put(`${SERVER_IP}${API_BASE}/Collection/editCollection`, body);
};
export const getCollectionByIdCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Collection/getcollectionbyid?ID=${id}`
  );
};
export const getCollectionItemsCall = async (id) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/getcollectionitembycollectionid`,
    id
  );
};
export const getlivecollectionitemsCall = async (id) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/getlivecollectionitems`,
    id
  );
};
export const getCollectionItemsByStatus = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/getcollectionitembycollectionid`,
    body
  );
};
export const getItemidsbyCollectionIdCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Collection/getItemidsbyCollectionId?CollectionId=${id}`
  );
};
export const updateCollectionStatusId = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/Collection/updateCollectionStatus`,
    body
  );
};
export const updateCollectionItemStatus = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/Collection/updatecollectionitemstatus`,
    body
  );
};

export const updateCollectionItemStatusBroadCast = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/BroadCast/updatecollectionitemstatus`,
    body
  );
};
export const CloseAuctionCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/BroadCast/closeAuction`, body);
};

export const discardfrompublishCall = async (id) => {
  return await post(
    `${SERVER_IP}${API_BASE}/admin/Collection/discardfrompublish`,
    id
  );
};

export const deleteLotByIdCall = async (Did) => {
  return await del(
    `${SERVER_IP}${API_BASE}/Collection/deletecollectionitembyid?Id=${Did}`
  );
};
export const getAllBusinessUsers = async () => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getallbusinessusers?PageSize=200&Page=1`
  );
};
export const getAllUserRoles = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Customer/getroles`);
};
export const deleteUserById = async (id) => {
  return await del(
    `${SERVER_IP}${API_BASE}/Customer/deletebusinessuser?id=${id}`
  );
};
export const editBusinessUser = async (body) => {
  return await put(`${SERVER_IP}${API_BASE}/Customer/editbusinessuser`, body);
};

export const getTimeZones = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Collection/gettimezoneinfo`);
};
export const getAllUsers = async () => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getallusers?PageSize=200&Page=1`
  );
};
export const getcustomeraddressCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getcustomeraddress?CustomerId=${id}`
  );
};

export const getDraftedCollections = async (page, pageSize, search) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Collection/getallcollection?status=2&page=${page}&pageSize=${pageSize}&searchText=${search}`
  );
};

export const getPublishedCollections = async (page, pageSize, search) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Collection/getallcollection?status=1&page=${page}&pageSize=${pageSize}&searchText=${search}`
  );
};

export const getbusinessuserbyidCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getbusinessuserbyid?Id=${id}`
  );
};

export const getAllAuctions = async () => {
  const body = {
    page: 1,
    pageSize: 50,
    getOnlyTotalCount: false,
    sortColumn: "StartOnUtc",
    sortDirection: "desc",
  };
  return await post(
    `${SERVER_IP}${API_BASE}/AuctionManagement/getallauctions`,
    body
  );
};

export const getPastAuctions = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/AuctionManagement/getpastauctions`,
    body
  );
};
export const getActiveAuctions = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/AuctionManagement/getactiveauctions`,
    body
  );
};
export const getUpcomingAuctions = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/AuctionManagement/getupcommingauctions`,
    body
  );
};

export const getAllCollectionRegistrations = async (id, page, PageSize) => {
  return await get(
    `${SERVER_IP}${API_BASE}/AuctionManagement/getallcollectionregistrations?CollectionId=${id}&Page=${page}&PageSize=${PageSize}`
  );
};

export const getAllCollectionWinnerDetails = async (id, page, PageSize) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Order/getCollectionWinnerDetails?CollectionId=${id}&Page=${page}&PageSize=${PageSize}`
  );
};

export const getBidsPlacedbyItem = async (id, page, PageSize) => {
  return await get(
    `${SERVER_IP}${API_BASE}/AuctionManagement/getbidsplacedbyitem?ItemId=${id}&Page=${page}&PageSize=${PageSize}`
  );
};
export const getAllCategories = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Collection/getitemcategories`);
};

export const saveLotInfoDetails = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/createcollectionitem`,
    body
  );
};

export const getsasToken = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Settings/getsastoken`);
};

export const imageasyncupload = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Settings/asyncupload`, body);
};
export const saveImagesforItem = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Collection/createitemimage`, body);
};
export const deleteImageByIdCall = async (id) => {
  return await del(
    `${SERVER_IP}${API_BASE}/Collection/deleteitemimagebyId?Id=${id}`
  );
};

export const edititemdescription = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/Collection/edititemdescription`,
    body
  );
};
export const newGetcollectionguidCall = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Collection/getcollectionguid`);
};

export const createitemprovenanceCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/createitemprovenance`,
    body
  );
};

export const edititemconditionalreportCall = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/Collection/edititemconditionalreport`,
    body
  );
};

export const createitemlogisticinfoCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/createitemlogisticinfo`,
    body
  );
};
export const edititemShippingDetailsCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Collection/editItemPackage`, body);
};

export const createitemShippingDetailsCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/createItemPackage`,
    body
  );
};

export const getcollectionitembyidCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Collection/getcollectionitembyid?id=${id}`
  );
};

export const edititemprovenanceCall = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/Collection/edititemprovenance`,
    body
  );
};
export const edititemlogisticinfoCall = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/Collection/edititemlogisticinfo`,
    body
  );
};

export const editcollectionitemCall = async (body) => {
  return await put(
    `${SERVER_IP}${API_BASE}/Collection/editcollectionitem`,
    body
  );
};
export const getcountriesCall = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Settings/getcountries`);
};
export const getcurrenciesCall = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Settings/getcurrencies`);
};
export const getstatesCall = async (CountryID) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Settings/getstates?CountryID=${CountryID}`
  );
};
export const getcitysCall = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Settings/getcountries`);
};

export const gethighestbid = async (id) => {
  return await get(`${SERVER_IP}${API_BASE}/Bid/gethighestbid?itemId=${id}`);
};
export const startNextLiveSessionCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/BroadCast/startNextLiveSession`,
    body
  );
};
export const choosewinnerCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/BroadCast/choosewinner`, body);
};
export const choosewinnerBidCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Bid/choosewinner`, body);
};
export const choosewinnerBroadCastCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/BroadCast/choosewinner`, body);
};

export const exitcollectionreviewCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/admin/Collection/exitcollectionreview`,
    body
  );
};
export const getpublishedcountCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/admin/Collection/getpublishedcount?CollectionId=${id}`
  );
};
export const placeLiveBidCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/BroadCast/placeLiveBid`, body);
};

export const autobidCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Bid/autobid`, body);
};
export const quickbidCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Bid/quickBid`, body);
};
export const quickbidCallBroadCast = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/BroadCast/quickbid`, body);
};

export const blockBid = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Bid/bidregistration`, body);
};
export const blockCustomer = async (body) => {
  return await put(`${SERVER_IP}${API_BASE}/Customer/blockcustomer`, body);
};
export const customerApprovelCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Customer/customerApprovel`, body);
};

export const getPaymentDetailsCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getCustomerPurchaseHistory?CustomerId=${id}&PageSize=200&Page=1`
  );
};
export const getApprovedUsersCall = async (page, PageSize) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getallusers?type=approved&PageSize=${PageSize}&Page=${page}`
  );
};
export const getPendingUsersCall = async (page, PageSize) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getallusers?type=pending&PageSize=${PageSize}&Page=${page}`
  );
};
export const getRejectedUsersCall = async (page, PageSize) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getallusers?type=rejected&PageSize=${PageSize}&Page=${page}`
  );
};
export const getOrderbyIdCall = async (id) => {
  return await get(`${SERVER_IP}${API_BASE}/Order/getOrderbyId?OrderId=${id}`);
};

export const rolesResetPasswordCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Customer/forgotPassword`, body);
};
export const getAllSalesReport = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Reporting/getSalesReport`, body);
};
