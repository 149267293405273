import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Accordion,
  Card,
  Nav,
  Button,
} from "react-bootstrap";
import BannerAuction from "./../../components/BannerAuction";
import LotsCard from "./../../components/LotsCard";
import LotsViewCard from "./../../components/LotsViewCard";
import FilterDataComponent from "./../../components/FilterComponent";
import FilterComponent from "./../../components/FilterComponent";
import { ReactComponent as PrintIcon } from "./../../assets/printIcon.svg";
import { ReactComponent as DownloadIcon } from "./../../assets/downloadIcon.svg";
import moment from "moment";
import { ReactComponent as Line } from "./../../assets/Line.svg";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import { ReactComponent as BlockIcon } from "./../../assets/blockIcon.svg";
import { ReactComponent as RedBlockIcon } from "./../../assets/redBlockIcon.svg";
import WinnersInfo from "./WinnersInfo";
import ReportsInfo from "./ReportsInfo";
import SearchComponent from "./../../components/SearchComponent";
import {
  UPDATE_FILTER_DATA,
  UPDATE_LOT_DATA,
} from "../../library/Requests/eventConstants";
import { triggerEventForProfileUpdate } from "../../Scenes/common";
import CustomModal from "./../../components/CustomModal";
import { useParams, useHistory } from "react-router-dom";
import {
  getAllCollectionRegistrations,
  getCollectionItemsCall,
  deleteLotByIdCall,
  getcurrenciesCall,
  updateCollectionItemStatus,
  getCollectionByIdCall,
  blockBid,
} from "./../../library/Requests";
import "./styles.css";

const AuctionLots = (props) => {
  let { id } = useParams();
  const params = new URLSearchParams(props.location.search);
  const type = params.get("type");

  const [currenciesData, setCurrenciesData] = useState([]);
  const [collectionItems, setCollectionItems] = useState([]);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [collectionDetailFull, setCollectionDetailFull] = useState({});
  const [deleteLotInfo, setDeleteLotInfo] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [withDrawLotID, setWithDrawLotID] = useState();
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [withdrawSuccessModal, setWithdrawSuccessModal] = useState(false);
  const [rePublishLot, setRePublishLot] = useState(false);
  const [rePublishedSuccessModal, setRePublishedSuccessModal] = useState(false);
  const [collectionData, setCollectionData] = useState({});
  const [bannerTimeZ, setBannerTimeZ] = useState([]);
  const [jsonBody, setJsonBody] = useState({});
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCnt, setTotalCnt] = useState(0);

  const [currentPages, setcurrentPages] = useState(1);
  const [setPageData, setSetPageData] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);

  const [blockedId, setBlockedId] = useState("");
  const [showBlockUserPopup, setShowBlockUserPopup] = useState(false);
  const [showBlockSuccessUserPopup, setShowBlockSuccessUserPopup] =
    useState(false);
  const [showUnBlockUserPopup, setShowUnBlockUserPopup] = useState(false);
  const [showUnBlockSuccessUserPopup, setShowUnBlockSuccessUserPopup] =
    useState(false);

  useEffect(async () => {
    window.scrollTo(0, 0);
    getcurrenciesDataCall();
    getDataonLoad();
  }, []);

  const getDataonLoad = async () => {
    const body = {
      page: 1,
      pageSize: 15,
      getOnlyTotalCount: false,
      collectionId: id,
      status: [1, 6, 7],
    };
    setJsonBody(body);
    getCollectionItems(body, "onLoad");
    getCollectionData(id);
    getRegisteredUsers(id, currentPages);
  };
  const withdrawnItems = collectionData?.withDrawnItems;

  let bannerTimeZone = bannerTimeZ.find(
    (i) => collectionData?.timeZoneId === i?.id
  )?.abbrivation;
  if (!bannerTimeZone) {
    bannerTimeZone = bannerTimeZ.find(
      (i) => collectionData?.timeZoneId === i?.id
    )?.name;
  }

  const getCollectionData = async (id) => {
    let resp = await getCollectionByIdCall(id);
    setCollectionData(resp?.data);
  };

  const getCollectionItems = async (id, type) => {
    setIsLoading(true);
    if (type != "viewmore") {
      setCollectionItems([]);
    }
    id.status = [1, 6, 7];
    let resp = await getCollectionItemsCall(id);
    if (type === "viewmore") {
      setIsLoading(false);
      setCollectionItems([...collectionItems, ...resp?.data]);
    } else {
      setIsLoading(false);
      setCollectionItems(resp?.data);
      setCollectionDetailFull(resp);
    }
    setTotalCnt(resp?.totalCount);
    if (type === "onLoad") {
      triggerEventForProfileUpdate(UPDATE_FILTER_DATA);
    }
  };

  const clickViewMore = () => {
    setCurrentPage(currentPage + 1);
    const body = jsonBody;
    body.page = currentPage + 1;
    getCollectionItems(body, "viewmore");
  };

  const getRegisteredUsers = async (id, currentPage1) => {
    setIsLoading(true);
    let resp = await getAllCollectionRegistrations(
      id,
      currentPage1,
      setPageData
    );
    setIsLoading(false);
    setRegisteredUsers(resp?.data);
    setTotalCount(resp.totalCount);
  };

  const eachPageNumberClick = (number) => {
    setcurrentPages(number);
    getRegisteredUsers(id, number);
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(totalCount / setPageData); i++) {
    pages.push(i);
  }
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => {
            eachPageNumberClick(number);
          }}
          className={currentPages == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const pageNextBtn = () => {
    setcurrentPages(currentPages + 1);
    getRegisteredUsers(id, currentPages + 1);
    if (currentPages + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const pagePrevBtn = () => {
    setcurrentPages(currentPages - 1);
    getRegisteredUsers(id, currentPages - 1);
    if ((currentPages - 1) % pageNumberLimit == 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const blockUser = (item) => {
    setShowBlockUserPopup(true);
    setBlockedId(item.id);
  };

  const blockUserConform = async () => {
    let id1 = blockedId;
    const body = {
      bidRegistration: {
        id: id1,
        blocked: true,
      },
    };
    const updatedUsers = registeredUsers;
    const index = updatedUsers.findIndex((itemL) => itemL.id == blockedId);
    if (index > 0) {
      updatedUsers[index].isBlocked = true;
      setRegisteredUsers(updatedUsers);
    }
    let resp = await blockBid(body);
    if (resp.success) {
      setShowBlockUserPopup(false);
      setShowBlockSuccessUserPopup(true);
      getDataonLoad();
    }
  };

  const UnBlockUser = (item) => {
    setShowUnBlockUserPopup(true);
    setBlockedId(item.id);
  };

  const unBlockUserConform = async () => {
    let id1 = blockedId;
    const body = {
      bidRegistration: {
        id: id1,
        blocked: false,
      },
    };
    const updatedUsers = registeredUsers;
    const index = updatedUsers.findIndex((itemL) => itemL.id == blockedId);
    if (index > 0) {
      updatedUsers[index].isBlocked = false;
      setRegisteredUsers(updatedUsers);
    }
    let resp = await blockBid(body);
    if (resp.success) {
      setShowUnBlockUserPopup(false);
      setShowUnBlockSuccessUserPopup(true);
      getDataonLoad();
    }
  };

  const getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    setCurrenciesData(resp?.data);
  };

  const deleteLot = async (lot) => {
    setDeleteModal(true);
    setDeleteLotInfo(lot);
  };

  const deleteLotConfirm = async (key) => {
    setDeleteModal(false);
    const Did = deleteLotInfo?.id;
    const resp = await deleteLotByIdCall(Did);
    const updatedLots = collectionItems;
    const index = updatedLots.findIndex((item) => item?.id === Did);
    if (index > -1) {
      updatedLots.splice(index, 1);
      setCollectionItems(updatedLots);
      setTotalCnt(parseInt(totalCnt) - 1);
    }
    setDeleteSuccessModal(true);
  };
  const checkwithDraw = (details) => {
    let returnV = false;
    for (var key in details) {
      if (key == "withDrawnItems" && details?.withDrawnItems?.length > 0) {
        returnV = true;
      }
    }
    return returnV;
  };
  const withdrawLot = async (id) => {
    setWithDrawLotID(id);
    setWithdrawModal(true);
  };
  const withdrawLotConfirm = async () => {
    setWithdrawModal(false);
    let body = {
      id: withDrawLotID,
      status: 3,
    };
    let resp = await updateCollectionItemStatus(body);
    setWithdrawSuccessModal(true);
    getDataonLoad();
  };

  const rePublisgtem = async (id) => {
    setWithDrawLotID(id);
    setRePublishLot(true);
  };
  const RePublishConfirm = async () => {
    setRePublishLot(false);
    let body = {
      id: withDrawLotID,
      status: 1,
    };
    let resp = await updateCollectionItemStatus(body);
    setRePublishedSuccessModal(true);
    getDataonLoad();
  };

  const searchClick = (search) => {
    const body = jsonBody;
    body.searchText = search;
    setSearchText(search);
    setJsonBody(body);
    getCollectionItems(body);
  };
  const itemCount = collectionItems?.length ?? 0;
  const createMarkup = (data) => {
    return { __html: data };
  };
  const history = useHistory();
  // const downLoadCall=()=>{
  //     history.push(`/dashboard/collectionDataDownload/${id}`);
  // }
  // const printCall=()=>{
  //     history.push(`/dashboard/collectionDataPrint/${id}`);
  // }
  return (
    <div className="lotsMatrixContainer">
      <BannerAuction />
      <Row className="filterLotsContainer">
        <Tab.Container id="collections-tabs" defaultActiveKey="browseLots">
          <Col sm="12" className="tabsColumn">
            <Nav variant="tabs" className="collectionsTabs">
              <Nav.Item>
                <Nav.Link eventKey="browseLots">
                  Browse Lots ({totalCnt})
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="overview">Overview</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="auctionDetails">Auction Details</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="conditionsOfSale">
                  Conditions Of Sale
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="registeredUsers">Registered Users</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="winners">Winners</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="reports">Reports</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>

          <Row className="filterLotsRow">
            {/* <Col sm="3" className="filterByColumn">
                    </Col> */}
            <Col sm="12" className="cardMatrix">
              <Tab.Content>
                <Tab.Pane eventKey="browseLots">
                  <Row className="browseLotIcons">
                    <SearchComponent
                      placeHolderText="Search Lots"
                      searchClickCall={searchClick}
                    />
                    {/* <DownloadIcon className="downloadIcon" onClick={()=>downLoadCall()}/> */}
                    <DownloadIcon className="downloadIcon" />
                    <PrintIcon className="printIcon" />
                    {/* <PrintIcon className="printIcon" onClick={()=>printCall()} /> */}
                  </Row>
                  <Row>
                    <Col sm="3" className="filterByColumn">
                      <FilterDataComponent
                        collectionData={collectionData}
                        collectionDetailFull={collectionDetailFull}
                        collectionid={id}
                        allCurrenciesData={currenciesData}
                        getUpdatedDataCall={getCollectionItems}
                      />
                    </Col>
                    <Col sm="9" className="noPadding">
                      <Row className="cardGroup ">
                        {collectionItems?.map((collectionItem, index) => {
                          return (
                            <>
                              <LotsCard
                                key={index.toString()}
                                index={index}
                                collectionItemData={collectionItem}
                                allCurrenciesData={currenciesData}
                                lotItems={collectionItems}
                                deleteCollectionItem={deleteLot}
                                withdrawCollectionItem={withdrawLot}
                                rePublisgCollectionItem={rePublisgtem}
                                collectionData={collectionData}
                              />
                            </>
                          );
                        })}
                      </Row>
                      {isLoading && (
                        <>
                          {" "}
                          <div className="spinner-borderWord">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </>
                      )}
                      {!isLoading && totalCnt > itemCount && (
                        <Row className="loadMoreRow">
                          <Button
                            onClick={() => clickViewMore()}
                            variant="custom"
                            className="loadMoreBtn"
                          >
                            Load More
                          </Button>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="overview">
                  <Row sm="12" className="scrollContent">
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        collectionData?.collectionOverview
                      )}
                    />
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="auctionDetails">
                  <Row className="auctionDetailSpacing">
                    <Row className="auctionNameValues">
                      <p>
                        <span className="auctionDetailsLeft">
                          Auction name:
                        </span>{" "}
                        <span className="adTitle">
                          {collectionData?.title}{" "}
                        </span>
                      </p>
                      <p>
                        <span className="auctionDetailsLeft">
                          Auction Date:{" "}
                        </span>
                        <span className="">
                          {collectionData?.auctionType == "1" ? (
                            <>
                              <span className="adDate">
                                {moment(collectionData?.onlineStartDate).format(
                                  "MMMM Do YYYY"
                                )}
                              </span>
                              <Line className="lineStyles" />
                              <span className="adTime">
                                {" "}
                                {moment(
                                  collectionData?.onlineStartTime,
                                  "HHmmss"
                                ).format("h:mm a")}{" "}
                                {bannerTimeZone}{" "}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="adDate">
                                {moment(collectionData?.liveStartDate).format(
                                  "MMMM Do YYYY"
                                )}
                              </span>
                              <Line className="lineStyles" />
                              <span className="adTime">
                                {" "}
                                {moment(
                                  collectionData?.liveStartTime,
                                  "HHmmss"
                                ).format("h:mm a")}{" "}
                                {bannerTimeZone}{" "}
                              </span>
                            </>
                          )}

                          <Line className="lineStyles" />
                          <span className="adOnlineAuction">
                            {" "}
                            {collectionData?.auctionType === 1
                              ? "Online"
                              : "Live"}{" "}
                            Auction
                          </span>
                        </span>
                      </p>
                      <p>
                        <span className="auctionDetailsLeft">
                          Number of Lots:{" "}
                        </span>{" "}
                        <span className="adTitle lotsNumberSpacing">
                          {" "}
                          {totalCnt}
                        </span>{" "}
                      </p>
                    </Row>
                    <p className="furtherInfoText">
                      Please get in touch with one of the specialists listed
                      below for further information.
                    </p>
                    <p className="forAssistText">
                      For assistance with bidding and registration, please
                      contact:
                    </p>
                    <p className="forAssistContent">+ 12345 67890</p>
                    <p className="forAssistContent">loremipsum@gmail.com</p>
                  </Row>
                  {checkwithDraw(collectionData) ? (
                    <>
                      <Accordion className="adAccordion" defaultActiveKey="0">
                        <Card>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            className="formHeading"
                          >
                            <span className="formHeading">
                              {" "}
                              Lot information1
                            </span>
                            <AngleUpIcon className="AngleUpIcon" />
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              {(withdrawnItems || [])?.map((item, i) => {
                                return (
                                  <Row className="adCardBlock">
                                    <p className="adCardHeading">
                                      Lot {item?.sno}{" "}
                                    </p>
                                    <p className="adCardContent">
                                      This Lot has been Withdrawn from the Sale.
                                    </p>
                                  </Row>
                                );
                              })}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </>
                  ) : null}
                </Tab.Pane>
                <Tab.Pane eventKey="conditionsOfSale">
                  <Row sm="12" className="scrollContent">
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        collectionData?.conditionOfSale
                      )}
                    />
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="registeredUsers">
                  <div className="Usertable-info">
                    <table responsive styles="width:100%">
                      <thead>
                        <tr className="headerNamesList">
                          <th>Bidding ID</th>
                          <th>Full name</th>
                          <th>Email Address</th>
                          <th>Phone Number</th>
                          <th>Prefered Mode of Bidding</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(registeredUsers || []).map((item, key) => {
                          return (
                            <>
                              <tr className="RowLevelClass bodyRowTextStyles">
                                <td> #{item.registrationNo} </td>
                                <td>
                                  {" "}
                                  {item.firstName} {item.lastName}{" "}
                                </td>
                                <td>{item.email}</td>
                                <td>{item.phoneNumber}</td>
                                <td> {item.preferedMode} </td>
                                {item.isBlocked ? (
                                  <td
                                    className="blockIcon"
                                    onClick={() => {
                                      UnBlockUser(item);
                                    }}
                                  >
                                    <RedBlockIcon />
                                  </td>
                                ) : (
                                  <td
                                    className="blockIcon"
                                    onClick={() => {
                                      blockUser(item);
                                    }}
                                  >
                                    <BlockIcon />
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                    <Row className="Paginationbtns">
                      {isLoading ? (
                        <>
                          <div className="spinner-borderWord">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </Row>
                    {registeredUsers.length > 0 ? (
                      <Row className="Paginationbtns">
                        <button
                          className="arrowBox"
                          onClick={pagePrevBtn}
                          style={{
                            display:
                              currentPages == pages[0] ? "none" : "block",
                          }}
                        >
                          {" "}
                          ...{" "}
                        </button>
                        <ul className="pageNumber">{renderPageNumbers}</ul>
                        <button
                          className="arrowBox paginationNextBtn"
                          onClick={pageNextBtn}
                          style={{
                            display:
                              currentPages == pages[pages.length - 1]
                                ? "none"
                                : "block",
                          }}
                        >
                          {" "}
                          ...{" "}
                        </button>
                      </Row>
                    ) : (
                      <Row className="Paginationbtns">
                        <p>No Users registered</p>
                      </Row>
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="winners">
                  <div className="winners-sec-info-cont">
                    <WinnersInfo />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="reports">
                  <div className="reports-sec-info-cont">
                    <ReportsInfo />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Row>

      <CustomModal
        show={deleteModal}
        titleText="Do you want to delete this Lot?"
        deleteText="Yes"
        cancelText="No"
        onCancel={() => setDeleteModal(false)}
        onDelete={() => deleteLotConfirm()}
      />
      <CustomModal
        show={deleteSuccessModal}
        titleText="Lot is Deleted Successfully"
        deleteText="ok"
        cancelText=""
        onDelete={() => setDeleteSuccessModal(false)}
      />

      <CustomModal
        show={withdrawModal}
        titleText="Do you want to Withdraw this Lot?"
        deleteText="Yes"
        cancelText="No"
        onCancel={() => setWithdrawModal(false)}
        onDelete={() => withdrawLotConfirm()}
      />
      <CustomModal
        show={withdrawSuccessModal}
        titleText="Lot is Withdrawn Successfully."
        deleteText="ok"
        cancelText=""
        onDelete={() => setWithdrawSuccessModal(false)}
      />
      <CustomModal
        show={rePublishLot}
        titleText="Do you want to Re-Publish this Lot?"
        deleteText="Yes"
        cancelText="No"
        onCancel={() => setRePublishLot(false)}
        onDelete={() => RePublishConfirm()}
      />
      <CustomModal
        show={rePublishedSuccessModal}
        titleText="Lot is Re-Published Successfully."
        deleteText="ok"
        cancelText=""
        onDelete={() => setRePublishedSuccessModal(false)}
      />
      <CustomModal
        show={showBlockUserPopup}
        titleText="Are you sure you want to block the user?"
        deleteText="Yes"
        cancelText="No"
        onCancel={() => setShowBlockUserPopup(false)}
        onDelete={() => blockUserConform()}
      />
      <CustomModal
        show={showBlockSuccessUserPopup}
        titleText="You have blocked user Successfully."
        deleteText="ok"
        cancelText=""
        onCancel={() => setShowBlockSuccessUserPopup(false)}
        onDelete={() => setShowBlockSuccessUserPopup(false)}
      />
      <CustomModal
        show={showUnBlockUserPopup}
        titleText="Are you sure you want to Unblock the user?"
        deleteText="Yes"
        cancelText="No"
        onCancel={() => setShowUnBlockUserPopup(false)}
        onDelete={() => unBlockUserConform()}
      />
      <CustomModal
        show={showUnBlockSuccessUserPopup}
        titleText="You have Unblocked user Successfully."
        deleteText="ok"
        cancelText=""
        onCancel={() => setShowUnBlockSuccessUserPopup(false)}
        onDelete={() => setShowUnBlockSuccessUserPopup(false)}
      />
    </div>
  );
};

export default AuctionLots;
