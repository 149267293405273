import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./styles.css";
import { ShippingLabelModelStyled } from "./ShippingLabelModel.styled";
import { getcustomeraddressCall } from "./../../../library/Requests";
const ShippingLabelModel = (props) => {
  const { onCancel } = props;
  const [selectedTExt, setSelectedTExt] = useState("");
  const [userAddressList, setUserAddressList] = useState([]);

  const handleValuesSelect = (value) => {
    setSelectedTExt(value);
    console.log(value);
  };
  useEffect(() => {
    getuserAddressDetails();
  }, []);
  const getuserAddressDetails = async () => {
    const resp1 = await getcustomeraddressCall(441);
    const listAdd = resp1?.address;
    console.log("listAdd", listAdd);
    setUserAddressList(listAdd);
    // const index = listAdd.findIndex((item: any) => item?.id == id);
  };
  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="registertoBidText1 create-shipping-label-content1">
        <ShippingLabelModelStyled>
          <div className="create-shipping-label-content">
            <h4 className="lebel-text">{"Create Shipping Label"}</h4>
            <div className="buyer-shipping-sec">
              <div className="buyer-shipping-sec-header">
                <h5>Buyer Shipping Addresses</h5>
              </div>
              <div className="body-content-shipping-sec">
                <p className="buyer-adddress-sec">Buyer Addresses</p>
                <p className="saved-adddress-sec">Saved Addresses</p>
                <div className="saved-address-all">
                  {userAddressList?.map((item) => {
                    return (
                      <div className="saved-address-item">
                        <div className="saved-adddress-left">
                          <input type="radio" />{" "}
                        </div>
                        <div className="saved-adddress-right">
                          <p className="saved-adddress-right-name">
                            Buyer Addresses
                          </p>
                          <p className="saved-adddress-right-address">
                            Saved Addresses
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="create-shipping-actions">
              <burron onClick={onCancel} className="create-shipping-cancel">
                Cancel
              </burron>
              <burron className="create-shipping-save">Save Changes</burron>
            </div>
          </div>

          <div className="dividerLineBid"></div>
        </ShippingLabelModelStyled>
      </Modal.Body>
    </Modal>
  );
};

export default ShippingLabelModel;
