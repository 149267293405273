import React, { useState, Component, useEffect } from "react";
import { Row, Col, Accordion, Card, Form, Button } from "react-bootstrap";
import { ReactComponent as AngleUpIcon } from "./../../assets/angleUp.svg";
import "./styles.css";
import {
  saveLotInfoDetails,
  editcollectionitemCall,
  getcountriesCall,
  getcurrenciesCall,
  getCollectionByIdCall,
} from "./../../library/Requests";

import { UPDATE_LOT_DATA } from "../../library/Requests/eventConstants";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Switch, Route, withRouter } from "react-router-dom";
import moment from "moment";

class AddLotForm extends Component {
  static propTypes = {};

  stateEm = {
    LotData: {
      collectionId: "",
      title: "",
      categoryId: "",
      countryCode: "",
      isTrending: false,
      makerName: "",
      objectDesc: "",
      material: "",
      isRestrictedMaterial: "",
      restrictedMaterialDesc: "",
      periodOfWork: "",
      width: "",
      length: "",
      height: "",
      converstion: "Cm",
      weightLb: "",
      weightOz: "",
      markings: "",
      damages: "",
      retoredInfo: "",
      awareOfRetoredInfo: "",
      appraisalsAndExhibition: "",
      initialBiddingPrice: "",
      reservedPrice: "",
      isFixedEstimatedPrice: "Fixed",
      estimatedPrice: "",
      estimatedPriceTo: "",
      isTrending: false,
    },
    notSure: {
      material: false,
      markings: false,
      damages: false,
      restrictedMaterialDesc: false,
    },
    errors: {
      title: false,
      categoryId: false,
      countryCode: false,
      makerName: false,
      objectDesc: false,
      material: false,
      markings: false,
      damages: false,
      initialBiddingPrice: false,
      reservedPrice: false,
      estimatedPrice: false,
      // estimatedPriceTo: false,
      isRestrictedMaterial: false,
      restrictedMaterialDesc: false,
    },
    errorsData: {
      title: "Please enter the title",
      categoryId: "Please Select the Category",
      countryCode: "Please select Country of Origin",
      makerName: "Please enter the Artist/ Maker",
      objectDesc: "Please enter the What is Object",
      material: "Please enter the Medium/ Material",
      markings: "Please enter the Signatures, Labels or Markings",
      damages: "Please enter the Areas of Damage",
      initialBiddingPrice: "Please enter the Initial Bidding Price",
      reservedPrice: "Please enter the Reserved Price",
      estimatedPrice: "Enter Estimated Price",
      estimatedPriceTo: "Enter Estimated Price",
      isRestrictedMaterial: "Please selct the Restricted Material type",
      restrictedMaterialDesc: "please eter the Restricted Material Desc",
    },
  };
  state = {
    ...this.stateEm,
    saveContinue: false,
    isFixedEstimatedPrice: false,
    allCountriesData: [],
    allCurrenciesData: [],
    collectionData: {},
    disableButton: false,
    initialBiddingPriceandEstimatedPrice: false,
    currencyName: "",
  };

  //   /api/Customer/getallusers
  componentDidMount = async () => {
    window.scrollTo(0, 50);
    this.getAllCountrys();
    if (this.props?.isEditLot) {
      this.updateUserDetails();
    }
    const self = this;
    window.document.addEventListener(
      UPDATE_LOT_DATA,
      function (event) {
        self.updateUserDetails();
      },
      false
    );
    let collID = 0;
    const searchLo = this.props.location.search || this.props.location.pathname;
    if (searchLo.indexOf("collectionId=") > 0) {
      collID = searchLo.replace("?collectionId=", "");
    } else if (searchLo.indexOf("collectionID=") > 0) {
      collID = searchLo.replace("?collectionID=", "");
    }
    if (collID) {
      this.getCollectionData(collID);
    }
  };
  getCollectionData = async (id) => {
    const collResp = await getcurrenciesCall();
    this.setState({ allCurrenciesData: collResp?.data });
    let resp = await getCollectionByIdCall(id);
    this.setState({ collectionData: resp?.data });
    let currencyName = collResp?.data.find(
      (i) => resp?.data?.currency == i?.id
    )?.currencyCode;
    this.setState({ currencyName: currencyName });
  };
  getAllCountrys = async () => {
    const resp = await getcountriesCall();
    this.setState({ allCountriesData: resp?.data });
  };
  getAllCurrencies = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ allCurrenciesData: resp?.data });
    console.log("allCurrenciesData_:", this.state.allCurrenciesData);
  };

  updateUserDetails = () => {
    try {
      const LotD1 = this.props?.lotItemDetails;
      const errorsFields = this.state.errors;
      console.log(LotD1);
      const details = this.state.LotData;
      for (var key in details) {
        details[key] = LotD1[key];
        if (key == "isRestrictedMaterial") {
          if (LotD1[key]) {
            details[key] = "yes";
            errorsFields.restrictedMaterialDesc = false;
          } else {
            details[key] = "no";
          }
        } else if (key == "isFixedEstimatedPrice") {
          if (LotD1[key]) {
            details[key] = "Fixed";
          } else {
            errorsFields.estimatedPriceTo = false;
            details[key] = "Range";
          }
        }
      }

      const notSurenew = this.state.notSure;
      for (var key in notSurenew) {
        const Pvalue = LotD1[key];
        if (Pvalue == "" || Pvalue == null || Pvalue == undefined) {
          notSurenew[key] = true;
        }
      }
      this.setState({
        LotData: details,
        notSure: notSurenew,
        errors: errorsFields,
      });
      // this.setState({LotData:details});
    } catch (e) {
      console.log(e);
    }

    // document.getElementById("hiddenInputForFORM").value = "4444";
    // setSaveContinueNew(true)
    // console.log(state);
  };

  dateFormatType = (date) => {
    var dtToday = new Date();
    if (date != undefined && date != "") {
      dtToday = new Date(date);
    }
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var DateNow = year + "-" + month + "-" + day;
    return DateNow;
  };

  handleChangeValue = (e) => {
    const { name, value } = e.target;
    const state = this.state.LotData;
    if (
      name == "initialBiddingPrice" ||
      name == "weightLb" ||
      name == "weightOz" ||
      name == "length" ||
      name == "height" ||
      name == "width" ||
      name == "reservedPrice" ||
      name == "estimatedPrice" ||
      name == "estimatedPriceTo"
    ) {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        state[name] = value;
        this.setState({ LotData: state });
      }
    } else {
      state[name] = value;
      this.setState({ LotData: state });
    }
    console.log(name);
    let errorsList = this.state.errors;
    errorsList[name] = false;
    console.log(errorsList);
    this.setState({
      errors: errorsList,
      initialBiddingPriceandEstimatedPrice: false,
    });
  };
  handleValuesSelect = (name, value) => {
    // setState({ ...state, [name]: value });
    // console.log(name);
    // console.log(value);
    const state = this.state.LotData;
    state[name] = value;

    let errorsList = this.state.errors;
    errorsList[name] = false;

    if (name == "isRestrictedMaterial") {
      if (value == "yes") {
        errorsList.restrictedMaterialDesc = false;
      } else {
        state.restrictedMaterialDesc = "";

        delete errorsList.restrictedMaterialDesc;
        // errorsList.restrictedMaterialDesc = false;
      }
    }
    if (name == "isFixedEstimatedPrice") {
      if (value == "Range") {
        errorsList.estimatedPriceTo = false;
      } else {
        state.estimatedPriceTo = "";
        delete errorsList.estimatedPriceTo;
      }
    }

    console.log(errorsList);
    this.setState({ LotData: state, errors: errorsList });
  };
  inputcheckBoxChange = (e) => {
    const { name, value } = e.target;
    const state = this.state.notSure;
    state[name] = e.target.checked;
    let errorsList = this.state.errors;
    if (e.target.checked) {
      errorsList[name] = false;
    }
    this.setState({ notSure: state, errors: errorsList });
  };
  isTrendingPageClick = (e) => {
    const { name, value } = e.target;
    const state = this.state.LotData;
    state[name] = e.target.checked;
    this.setState({ LotData: state });
  };
  validateFormData = () => {
    const details = this.state.errors;
    let errorsList = this.state.errors;
    let notSureList = this.state.notSure;
    let returnFlag = true;
    for (var key in details) {
      const Pvalue = this.state.LotData[key];
      const notval = notSureList[key];
      if (Pvalue == "" || Pvalue == null || Pvalue == undefined) {
        if (notval == undefined || !notval) {
          errorsList[key] = true;
          returnFlag = false;
        }
      }
    }
    const {
      reservedPrice,
      initialBiddingPrice,
      isFixedEstimatedPrice,
      estimatedPrice,
      estimatedPriceTo,
    } = this.state.LotData;

    if (initialBiddingPrice != "" && reservedPrice != "") {
      if (parseInt(initialBiddingPrice) > parseInt(reservedPrice)) {
        errorsList.reservedPrice = true;
        returnFlag = false;
      }
    }
    if (initialBiddingPrice != "" && estimatedPrice != "") {
      if (parseInt(initialBiddingPrice) >= parseInt(estimatedPrice)) {
        returnFlag = false;
        this.setState({ initialBiddingPriceandEstimatedPrice: true });
      }
    }

    if (this.state?.LotData?.isFixedEstimatedPrice == "Range") {
      if (estimatedPriceTo == "") {
        errorsList.estimatedPriceTo = true;
        returnFlag = false;
      } else if (estimatedPriceTo != "" && estimatedPrice != "") {
        console.log("3333");
        console.log(parseInt(estimatedPrice));
        console.log(parseInt(estimatedPriceTo));

        if (parseInt(estimatedPrice) >= parseInt(estimatedPriceTo)) {
          errorsList.estimatedPrice = true;
          returnFlag = false;
        } else {
          errorsList.estimatedPrice = false;
        }
      }
      // estimatedPrice: "",
      // estimatedPriceTo: "",

      // {errorsData?.initialBiddingPrice}
      // {errorsData?.reservedPrice}
    }

    this.setState({ errors: errorsList });
    return returnFlag;
  };
  // updateHiddenvalue = (e)=>{
  //     setSaveContinueNew(true)
  //     console.log("44444444444")
  // }
  saveLotInfo = async () => {
    if (this.validateFormData()) {
      const state = this.state.LotData;
      console.log("22313", state);
      console.log(this.props.collectionIdNew);
      const details = state;
      if (this.props.collectionIdNew != "") {
        window.scrollTo(0, 100);
        const body = {};
        body.collectionId = this.props.collectionIdNew;
        for (var key in details) {
          console.log(key);

          if (
            (details[key] != null && details[key] != "") ||
            key == "isTrending"
          ) {
            let aVal = details[key];
            if (key == "isRestrictedMaterial") {
              if (details[key] == "yes") {
                aVal = true;
              } else {
                aVal = false;
              }
            } else if (key == "isFixedEstimatedPrice") {
              if (details[key] == "Fixed") {
                aVal = true;
              } else {
                aVal = false;
              }
            } else {
              aVal = details[key];
            }
            console.log(aVal);
            body[key] = aVal;
            console.log(details[key]);
          }
        }
        console.log(body);

        if (this.props.isEditLot || this.state.saveContinue) {
          this.setState({ disableButton: true });
          body.id =
            this.props?.lotItemDetails?.id || this.props?.collectionItemId;
          const resp = await editcollectionitemCall({ collectionItem: body });
          if (resp.success) {
            this.props.UpdatecollectionItemId(resp?.collectionItemId);
            document.getElementById("Accordion_Section_1").click();
            this.setState({ saveContinue: true, disableButton: false });
            this.props.getItemDetailsCall();
          }
        } else {
          this.setState({ disableButton: true });
          const resp = await saveLotInfoDetails({ collectionItem: body });
          if (resp.success) {
            this.props.UpdatecollectionItemId(resp?.collectionItemId);
            document.getElementById("Accordion_Section_1").click();
            this.setState({ saveContinue: true, disableButton: false });
            this.props.getItemDetailsCall();
          }
        }
      }
    }
  };

  render() {
    const {
      saveContinue,
      disableButton,
      isFixedEstimatedPrice,
      LotData,
      errors,
      allCurrenciesData,
      allCountriesData,
      errorsData,
      notSure,
      currencyName,
    } = this.state;

    const { allCategories } = this.props;

    // let currendyCode = currenciesData.find(i => collectionData?.currency == i?.id)?.currencyCode;

    return (
      <>
        <div className="apper">
          <Card>
            <Accordion.Toggle
              id="Accordion_Section_0"
              defaultActiveKey="0"
              as={Card.Header}
              eventKey="0"
            >
              <span className="formHeading">1. Lot Details</span>
              {/* <input type="hidden" id="hiddenInputForFORM" onChange={updateHiddenvalue} value="88"></input> */}

              {saveContinue ? (
                <Button className="AccordionEditBtn"> Edit </Button>
              ) : (
                <AngleUpIcon className="angleUpIcon" />
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="lotDetailsBody">
                <Form.Group>
                  <Form.Label className="requiredField">Title</Form.Label>
                  <Form.Control
                    maxLength="300"
                    isInvalid={errors?.title}
                    value={LotData?.title}
                    type="text"
                    name="title"
                    onChange={this.handleChangeValue}
                    placeholder="Enter Title"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.title ? <>{errorsData?.title}</> : null}
                  </Form.Control.Feedback>
                  <Form.Label className="requiredField">Category</Form.Label>
                  <Form.Control
                    isInvalid={errors?.categoryId}
                    name="categoryId"
                    onChange={this.handleChangeValue}
                    as="select"
                  >
                    <option name="">Select Category</option>
                    {(allCategories || []).map((item, key) => {
                      const selecVAl =
                        LotData?.categoryId == item.id ? true : false;
                      return (
                        <>
                          <option
                            selected={selecVAl}
                            name={item.id}
                            value={item.id}
                          >
                            {item.category}
                          </option>
                        </>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors?.categoryId ? <>{errorsData?.categoryId}</> : null}
                  </Form.Control.Feedback>
                  <Form.Check
                    inline
                    type="checkbox"
                    className="lotDetailsCheckbox"
                    label="Show in Trending"
                    name="isTrending"
                    checked={LotData?.isTrending}
                    onChange={this.isTrendingPageClick}
                  />

                  <Form.Label className="requiredField">
                    Item's Country of Origin
                  </Form.Label>
                  <Form.Control
                    name="countryCode"
                    isInvalid={errors?.countryCode}
                    onChange={this.handleChangeValue}
                    as="select"
                  >
                    <option>Select Country Name</option>
                    {(allCountriesData || []).map((item, index) => (
                      <option
                        selected={
                          LotData?.countryCode == item?.code ? true : false
                        }
                        name="countryCode"
                        value={item?.code}
                      >
                        {item?.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors?.countryCode ? (
                      <>{errorsData?.countryCode}</>
                    ) : null}
                  </Form.Control.Feedback>

                  <Form.Label className="requiredField">
                    Artist/ Maker
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={errors?.makerName}
                    value={LotData?.makerName}
                    name="makerName"
                    onChange={this.handleChangeValue}
                    placeholder="Enter Artist Name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.makerName ? <>{errorsData?.makerName}</> : null}
                  </Form.Control.Feedback>

                  <Form.Label className="requiredField">
                    What is Object
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={errors?.objectDesc}
                    value={LotData?.objectDesc}
                    name="objectDesc"
                    onChange={this.handleChangeValue}
                    placeholder="Enter Object Name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.objectDesc ? <>{errorsData?.objectDesc}</> : null}
                  </Form.Control.Feedback>

                  <Form.Label className="requiredField">
                    Medium/ Material
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={errors?.material}
                    value={LotData?.material}
                    name="material"
                    onChange={this.handleChangeValue}
                    placeholder="Enter Medium/ Material name"
                  />

                  <Form.Check
                    inline
                    type="checkbox"
                    className="lotDetailsCheckbox"
                    label="I am not Sure"
                    checked={notSure?.material}
                    name="material"
                    onChange={this.inputcheckBoxChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.material ? <>{errorsData?.material}</> : null}
                  </Form.Control.Feedback>

                  <Form.Label className="requiredField">
                    Lot contains restricted materials
                  </Form.Label>
                  <Form.Check
                    inline
                    type="radio"
                    onClick={() => {
                      this.handleValuesSelect("isRestrictedMaterial", "yes");
                    }}
                    label="Yes"
                    checked={
                      LotData?.isRestrictedMaterial == "yes" ? true : false
                    }
                    name="restricted_materials"
                    className="lotRestrictedRadio"
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="No"
                    checked={
                      LotData?.isRestrictedMaterial == "no" ? true : false
                    }
                    onClick={() => {
                      this.handleValuesSelect("isRestrictedMaterial", "no");
                    }}
                    name="restricted_materials"
                    className="lotRestrictedRadio"
                  />
                  {errors?.isRestrictedMaterial ? (
                    <>
                      <div className="invalid-feedbackText">
                        {errorsData?.isRestrictedMaterial}
                      </div>
                    </>
                  ) : null}

                  {LotData?.isRestrictedMaterial == "yes" && (
                    <>
                      <Form.Label className="mentionHere requiredField">
                        Mention here
                      </Form.Label>
                      <Form.Control
                        type="text"
                        isInvalid={errors?.restrictedMaterialDesc}
                        value={LotData?.restrictedMaterialDesc}
                        name="restrictedMaterialDesc"
                        onChange={this.handleChangeValue}
                        placeholder="Enter restricted Details"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.restrictedMaterialDesc ? (
                          <>{errorsData?.restrictedMaterialDesc}</>
                        ) : null}
                      </Form.Control.Feedback>
                      <Form.Check
                        inline
                        type="checkbox"
                        className="lotDetailsCheckbox"
                        label="I am not Sure"
                        checked={notSure?.restrictedMaterialDesc}
                        name="restrictedMaterialDesc"
                        onChange={this.inputcheckBoxChange}
                      />
                    </>
                  )}
                  <Form.Label>Date/Period of Work</Form.Label>
                  <Form.Control
                    // value={LotData?.periodOfWork}
                    // min = {this.dateFormatType()}
                    value={moment(LotData?.periodOfWork).format("YYYY-MM-DD")}
                    onChange={this.handleChangeValue}
                    className="start_date_select"
                    type="date"
                    name="periodOfWork"
                    placeholder="Date of Birth"
                  />

                  <Form.Label className="">Measurements</Form.Label>
                  <Form.Check
                    inline
                    type="radio"
                    onClick={() => {
                      this.handleValuesSelect("converstion", "Cm");
                    }}
                    label="Cm"
                    name="measurementsType"
                    className="measurementsType"
                    checked={LotData?.converstion == "Cm" ? true : false}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Inches"
                    onClick={() => {
                      this.handleValuesSelect("converstion", "Inches");
                    }}
                    name="measurementsType"
                    className="measurementsType"
                    checked={LotData?.converstion == "Inches" ? true : false}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Feet"
                    onClick={() => {
                      this.handleValuesSelect("converstion", "Feet");
                    }}
                    name="measurementsType"
                    className="measurementsType"
                    checked={LotData?.converstion == "Feet" ? true : false}
                  />
                  <Row className="rangeFields">
                    <Col sm="2.2" style={{ padding: 0 }}>
                      <Form.Label className="measurementLabel ">
                        Height
                      </Form.Label>
                      <Form.Control
                        value={LotData?.height}
                        name="height"
                        onChange={this.handleChangeValue}
                        className=" measurementFieldsspacing"
                        type="text"
                        placeholder="Enter Height"
                      />
                    </Col>
                    <Col sm="2.2" style={{ padding: 0 }}>
                      <Form.Label className="measurementLabel ">
                        Width
                      </Form.Label>
                      <Form.Control
                        value={LotData?.width}
                        name="width"
                        onChange={this.handleChangeValue}
                        className=" measurementFieldsspacing"
                        type="text"
                        placeholder="Enter Width"
                      />
                    </Col>
                    <Col sm="2.2" style={{ padding: 0 }}>
                      <Form.Label className="measurementLabel ">
                        Depth
                      </Form.Label>
                      <Form.Control
                        value={LotData?.length}
                        name="length"
                        onChange={this.handleChangeValue}
                        className=" measurementFieldsspacing"
                        type="text"
                        placeholder="Enter Depth"
                      />
                    </Col>
                  </Row>

                  <Form.Label className="">Weight</Form.Label>
                  <Row>
                    <Form.Control
                      value={LotData?.weightLb}
                      name="weightLb"
                      onChange={this.handleChangeValue}
                      className=" measurementFieldsspacingWeight"
                      type="text"
                      placeholder="lb"
                    />

                    <Form.Control
                      value={LotData?.weightOz}
                      name="weightOz"
                      onChange={this.handleChangeValue}
                      className=" measurementFieldsspacingWeight"
                      type="text"
                      placeholder="oz"
                    />
                  </Row>

                  <Form.Label className="requiredField">
                    Signatures, Labels or Markings
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={errors?.markings}
                    value={LotData?.markings}
                    name="markings"
                    onChange={this.handleChangeValue}
                    placeholder="Enter signatures, Labels or Markings name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.markings ? <>{errorsData?.markings}</> : null}
                  </Form.Control.Feedback>
                  <Form.Check
                    inline
                    type="checkbox"
                    className="lotDetailsCheckbox"
                    label="I am not Sure"
                    checked={notSure?.markings}
                    name="markings"
                    onChange={this.inputcheckBoxChange}
                  />

                  <Form.Label className="requiredField">
                    Areas of Damage
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={errors?.damages}
                    value={LotData?.damages}
                    name="damages"
                    onChange={this.handleChangeValue}
                    placeholder="Enter Areas of Damages"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.damages ? <>{errorsData?.damages}</> : null}
                  </Form.Control.Feedback>
                  <Form.Check
                    inline
                    type="checkbox"
                    className="lotDetailsCheckbox"
                    label="I am not Sure"
                    checked={notSure?.damages}
                    name="damages"
                    onChange={this.inputcheckBoxChange}
                  />

                  <Form.Label>
                    Has it been restored? If so, to what extent
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={LotData?.retoredInfo}
                    name="retoredInfo"
                    onChange={this.handleChangeValue}
                    placeholder="Enter Details"
                  />

                  <Form.Check
                    inline
                    type="checkbox"
                    className="lotDetailsCheckbox"
                    label="I am not Sure"
                  />

                  <Form.Label>Appraisals/ Publication/ Exhibition</Form.Label>
                  <Form.Control
                    type="text"
                    value={LotData?.appraisalsAndExhibition}
                    name="appraisalsAndExhibition"
                    onChange={this.handleChangeValue}
                    placeholder="Enter Appraisals/ Publication/ Exhibtions name"
                  />

                  <Form.Check
                    inline
                    type="checkbox"
                    className="lotDetailsCheckbox"
                    label="I am not Sure"
                  />

                  <Row>
                    <Col sm="4" style={{ padding: 0 }}>
                      <Form.Label className="requiredField">
                        Initial Bidding Price
                      </Form.Label>
                      <Form.Control
                        isInvalid={errors?.initialBiddingPrice}
                        value={LotData?.initialBiddingPrice}
                        name="initialBiddingPrice"
                        onChange={this.handleChangeValue}
                        className="mediumFields"
                        type="text"
                        placeholder={`Enter intial Bidding Price`}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.initialBiddingPrice ? (
                          <>{errorsData?.initialBiddingPrice}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm="4" style={{ marginLeft: "29px" }}>
                      <Form.Label className="requiredField">
                        Reserved Price
                      </Form.Label>
                      <Form.Control
                        isInvalid={errors?.reservedPrice}
                        value={LotData?.reservedPrice}
                        name="reservedPrice"
                        onChange={this.handleChangeValue}
                        className="mediumFields"
                        type="text"
                        placeholder={`Enter Reserve Price`}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.reservedPrice ? (
                          <>{errorsData?.reservedPrice}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>

                  <Form.Label
                    inline
                    className="estimatedPriceLabel requiredField"
                  >
                    Estimated Price
                  </Form.Label>
                  <Form.Check
                    inline
                    name="estimatedPriceLabel_value"
                    // onClick={() => { this.handleValuesSelect('isFixedEstimatedPrice', true) }}
                    value={true}
                    type="radio"
                    label="Fixed"
                    checked={
                      LotData?.isFixedEstimatedPrice == "Fixed" ? true : false
                    }
                    onChange={(e) => {
                      this.handleValuesSelect("isFixedEstimatedPrice", "Fixed");
                    }}
                  />
                  <Form.Check
                    inline
                    name="estimatedPriceLabel_value"
                    // onClick={() => { this.handleValuesSelect('isFixedEstimatedPrice', false) }}
                    value={false}
                    type="radio"
                    label="Range"
                    checked={
                      LotData?.isFixedEstimatedPrice == "Range" ? true : false
                    }
                    onChange={(e) => {
                      this.handleValuesSelect("isFixedEstimatedPrice", "Range");
                    }}
                  />

                  <Row className="rangeFields">
                    <Col sm="2" style={{ padding: 0 }}>
                      <Form.Control
                        isInvalid={errors?.estimatedPrice}
                        value={LotData?.estimatedPrice}
                        name="estimatedPrice"
                        onChange={this.handleChangeValue}
                        className="smallFields"
                        type="text"
                        placeholder={
                          LotData?.isFixedEstimatedPrice === "Fixed"
                            ? `Enter Fixed Price`
                            : `Enter Min. Price`
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.estimatedPrice ? (
                          <>{errorsData?.estimatedPrice}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Col>
                    {LotData?.isFixedEstimatedPrice == "Range" && (
                      <>
                        <Col
                          sm="0.5"
                          className={
                            errors?.estimatedPrice ? "toTextPadding" : "toText"
                          }
                        >
                          to
                        </Col>
                        <Col sm="2" style={{ padding: 0 }}>
                          <Form.Control
                            isInvalid={errors?.estimatedPriceTo}
                            value={LotData?.estimatedPriceTo}
                            name="estimatedPriceTo"
                            onChange={this.handleChangeValue}
                            className="smallFields"
                            type="text"
                            placeholder={`Enter Max. Price`}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.estimatedPriceTo ? (
                              <>{errorsData?.estimatedPriceTo}</>
                            ) : null}
                          </Form.Control.Feedback>
                        </Col>
                      </>
                    )}
                  </Row>
                  {this.state.initialBiddingPriceandEstimatedPrice && (
                    <div className="errrorFieldsValue">
                      Estimated price should be more than initial bidding price
                    </div>
                  )}
                  <Row className="btnRow btnAlign">
                    <Button onClick={this.saveLotInfo} className="saveContinue">
                      Save & Continue
                    </Button>
                  </Row>
                </Form.Group>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </div>
      </>
    );
  }
}

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//         AddLotForm,
//     },
//     dispatch
//   );

export default withRouter(AddLotForm);

// const AddLotForm = (props) => {
//     const { allCategories } = props;
//     // const LotD = props?.lotItemDetails?.itemLogisticInfos
//     // console.log(props);
//     const [state, setState] = useState({

//     })

//     console.log("333333");
//     // console.log(props?.isEditLot);
//     useEffect(() => {

//     }, [props]);

//     const

//     console.log("state,", state)
//     return (

//     )
// }

// export default AddLotForm
